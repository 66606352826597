<div class="d-sm-flex align-items-center justify-content-between mb-4">
  <h5 class="h3 mb-0 text-gray-800">Please add Service Type
  </h5>
</div>

<form [formGroup]="ServiceTypeForm" (ngSubmit)="onSubmit()" class="user">
  <div class="form-group row">
    <div class="col-sm-6 mb-3 mb-sm-0">
      <app-text-input formControlName="name" [label]="'Service Type Name'"></app-text-input>
    </div>
    <div class="col-sm-6">
      <select formControlName="type" class="form-control" >
        <option value="1">Primary service</option>
        <option value="2">Secondary service</option>
      </select>
    </div>
  </div>
  <div class="form-group">
    <app-text-input formControlName="description" [label]="'Description'"></app-text-input>
  </div>
  <div class="row">
    <div class="col-md-6">
      <a class="btn btn-primary btn-user btn-block" style="color:white" (click)="onSubmit()">
        Add Service Type
      </a>
    </div>
    <div class="col-md-6">
      <a href="javascript:void(0)" (click)="onCancel.emit()" class="btn btn-google btn-user btn-block">
        Cancel
      </a>
    </div>
  </div>
</form>