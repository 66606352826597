import { Component, HostListener, OnInit } from '@angular/core';
import { IActivateDeactivateServiceProvider } from '../shared/models/activateDeactivateServiceProvider';
import { IServiceProvider } from '../shared/models/serviceProvider';
import { ServiceProviderService } from './service-provider.service';

export class serviceProviders {
  public Name: string;
  public Location: string;
}
@Component({
  selector: 'app-service-provider-management',
  templateUrl: './service-provider-management.component.html',
  styleUrls: ['./service-provider-management.component.scss']
})

export class ServiceProviderManagementComponent implements OnInit {
  public selectedServiceProvderName: string = null;
  public listOfSPS: Array<IServiceProvider> = [];
  public isServiceProviderTableVisible = true;
  public isAddServiceProviderFormVisible = false;
  public isEditServiceProviderFormVisible = false;
  public isManageUserServiceProviderFormVisible = false;
  public isManageUserPasswordServiceProviderFormVisible = false;
  public isManageServicesServiceProviderFormVisible = false;
  public isAddServiceType = false;
  public selectedServiceProviderId = 0;
  public isAddServiceTypeFormVisible = false;
  public innerWidth: number;


  constructor(private serviceProviderService: ServiceProviderService) {

  }

  ngOnInit(): void {
    this.getListOfServiceProviders();
    this.innerWidth = window.innerWidth;
  }

  public getListOfServiceProviders() {
    this.serviceProviderService.getServiceProviders().subscribe((res: Array<IServiceProvider>) => {
      if (res && res.length > 0) {
        this.listOfSPS = res;
      }
    });
  }

  public showAddServiceProviderForm() {
    this.isAddServiceProviderFormVisible = true;
    this.isServiceProviderTableVisible = false;
  }

  public showAddServiceTypeForm() {
    this.isAddServiceTypeFormVisible = true;
    this.isServiceProviderTableVisible = false;
  }


  public closeAddSPForm() {
    this.selectedServiceProvderName = null;
    this.isAddServiceProviderFormVisible = false;
    this.isAddServiceTypeFormVisible = false;
    this.isManageUserServiceProviderFormVisible = false;
    this.isEditServiceProviderFormVisible = false;
    this.isManageUserPasswordServiceProviderFormVisible = false;
    this.isManageServicesServiceProviderFormVisible = false;
    this.isServiceProviderTableVisible = true;
  }

  public onSuccessfullRegister() {
    this.getListOfServiceProviders();
    this.closeAddSPForm();
  }

  public onSuccessfullPasswordUpdate() {
    this.getListOfServiceProviders();
    this.closeAddSPForm();
  }

  public onServiceProviderEditClicked(id: number, name: string) {
    this.setServiceProviderName(name);
    this.selectedServiceProviderId = id;
    this.isEditServiceProviderFormVisible = true;
    this.isServiceProviderTableVisible = false;
  }

  public onServiceProviderProfileManageClicked(id: number, name: string) {
    this.setServiceProviderName(name);
    this.selectedServiceProviderId = id;
    this.isManageUserServiceProviderFormVisible = true;
    this.isServiceProviderTableVisible = false;
  }

  public onServiceProviderPasswordManageClicked(id: number, name: string) {
    this.setServiceProviderName(name);
    this.selectedServiceProviderId = id;
    this.isManageUserPasswordServiceProviderFormVisible = true;
    this.isServiceProviderTableVisible = false;
  }

  public onManageServiceProviderServicesClicked(id: number, name: string) {
    this.setServiceProviderName(name);
    this.selectedServiceProviderId = id;
    this.isManageServicesServiceProviderFormVisible = true;
    this.isServiceProviderTableVisible = false;
  }

  public setServiceProviderName(name: string) {
    this.selectedServiceProvderName = name;
  }

  public activateServiceProvider(id: number) {
    let activateServiceProvider: IActivateDeactivateServiceProvider = {
      serviceProviderId: id
    };
    this.serviceProviderService.activateServiceProvider(activateServiceProvider).subscribe(res => {
      if (res) {
        let updatedSP = this.listOfSPS.filter(SP => SP.id === id)[0];
        updatedSP.active = true;
        this.listOfSPS.filter(SP => SP.id === id)[0] = updatedSP;
      }
    });
  }

  public deactivateServiceProvider(id: number) {
    this.serviceProviderService.checkServiceProviderActiveBookings(id).subscribe(res => {
      if(!res){
      let deactivateServiceProvider: IActivateDeactivateServiceProvider = {
        serviceProviderId: id
      };
      this.serviceProviderService.deactivateServiceProvider(deactivateServiceProvider).subscribe(res =>{
        if (res) {
          let updatedSP = this.listOfSPS.filter(SP => SP.id === id)[0];
          updatedSP.active = false;
          this.listOfSPS.filter(SP => SP.id === id)[0] = updatedSP;
        }
      });
    }
    else{
      alert("Service provider has active bookings can't deactivate at this moment.")
    }
  });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    console.log(this.innerWidth);
  }
}
