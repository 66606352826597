<div class="row" style="margin-bottom: 10px;" *ngIf="!this.isAddServiceTypeActive && this.serviceTypes.length > 0">
    <div class="col-md-12">
        <button (click)="showAddServiceTypeTable()" class="btn btn-info">
            <i class="fa fa-plus"></i> Assign Service Type
        </button>
    </div>
</div>

<!-- Content Row -->
<div class="row" *ngIf="!this.isAddServiceTypeActive">
    <div class="col-md-12" *ngIf="innerWidth > 1200">
        <div class="table-responsive">
            <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th style="width: 6rem;">Total Duration</th>
                        <th style="width: 6rem;">Wash Duration</th> 
                        <th style="width: 6rem;">Dry Duration</th>  
                        <th style="width: 6rem;">Rate</th>
                        <th style="width: 18rem; text-align: center;">Actions</th>
                    </tr>
                </thead>
                <tbody *ngIf="this.serviceProviderServiceTypes.length > 0">
                    <tr *ngFor="let item of this.serviceProviderServiceTypes">
                        <td>{{ item.name }}</td>
                        <td>{{ item.description }}</td>
                        <td>
                            <ng-container *ngIf="this.selectedServiceProviderServiceTypeId !== item.id">
                                {{ item.duration }}
                            </ng-container>
                            <ng-container *ngIf="this.selectedServiceProviderServiceTypeId === item.id">
                                <input class="form-control" type="time" timeformat="24h" (keyup)="updateDuration($event, item.id)" value="{{item.duration| date:'d/MM/yyyy HH:mm'}}" />
                            </ng-container> 
                        </td>
                        <td>
                            <ng-container *ngIf="this.selectedServiceProviderServiceTypeId !== item.id">
                                {{ item.washDuration }}
                            </ng-container>
                            <ng-container *ngIf="this.selectedServiceProviderServiceTypeId === item.id">
                                <input class="form-control" type="time" timeformat="24h" (keyup)="updateWashDuration($event, item.id)" value="{{item.washDuration | date:'d/MM/yyyy HH:mm'}}" />
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="this.selectedServiceProviderServiceTypeId !== item.id">
                                {{ item.dryDuration }}
                            </ng-container>
                            <ng-container *ngIf="this.selectedServiceProviderServiceTypeId === item.id">
                                <input class="form-control" type="time" timeformat="24h" (keyup)="updateDryDuration($event, item.id)" value="{{item.dryDuration | date:'d/MM/yyyy HH:mm'}}" />
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="this.selectedServiceProviderServiceTypeId !== item.id">
                                {{ item.rate }}
                            </ng-container>
                            <ng-container *ngIf="this.selectedServiceProviderServiceTypeId === item.id">
                                <input class="form-control" type="number" (keyup)="updateRate($event, item.id)" [value]="item.rate" />
                            </ng-container>
                        </td>
                        <td>
                            <button class="btn btn-link" title="Edit Service Provider" (click)="onServiceProviderServiceTypeEditClicked(item)">
                                <i class="fa fa-pencil"></i>
                            </button>
                            <button class="btn btn-link" title="Delete Service Type From Service Provider" (click)="onServiceProviderServiceTypeDeleted(item.id)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot *ngIf="this.serviceProviderServiceTypes.length === 0">
                    <tr>
                        <td colspan="7" style="text-align: center;">No Service Types have been added to this Service Provider</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <!-- Responsive Table for Smaller Screens -->
    <div class="col-12" *ngIf="innerWidth < 1200">
        <div class="responsive-table" style="max-width: fit-content;">
            <table class="responsive-table table-bordered" id="dataTable" width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th style="width: 6rem;">Total Duration</th>
                        <th style="width: 6rem;">Wash Duration</th> 
                        <th style="width: 6rem;">Dry Duration</th>  
                        <th style="width: 6rem;">Rate</th>
                        <th style="width: 18rem; text-align: center;">Actions</th>
                    </tr>
                </thead>
                <tbody *ngIf="this.serviceProviderServiceTypes.length > 0">
                    <tr *ngFor="let item of this.serviceProviderServiceTypes">
                        <td>{{ item.name }}</td>
                        <td>{{ item.description }}</td>
                        <td>
                            <ng-container *ngIf="this.selectedServiceProviderServiceTypeId !== item.id">
                                {{ item.duration }}
                            </ng-container>
                            <ng-container *ngIf="this.selectedServiceProviderServiceTypeId === item.id">
                                <input class="form-control" type="number" (keyup)="updateDuration($event, item.id)" [value]="item.duration" />
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="this.selectedServiceProviderServiceTypeId !== item.id">
                                {{ item.washDuration }}
                            </ng-container>
                            <ng-container *ngIf="this.selectedServiceProviderServiceTypeId === item.id">
                                <input class="form-control" type="number" (keyup)="updateWashDuration($event, item.id)" [value]="item.washDuration" />
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="this.selectedServiceProviderServiceTypeId !== item.id">
                                {{ item.dryDuration }}
                            </ng-container>
                            <ng-container *ngIf="this.selectedServiceProviderServiceTypeId === item.id">
                                <input class="form-control" type="number" (keyup)="updateDryDuration($event, item.id)" [value]="item.dryDuration" />
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="this.selectedServiceProviderServiceTypeId !== item.id">
                                {{ item.rate }}
                            </ng-container>
                            <ng-container *ngIf="this.selectedServiceProviderServiceTypeId === item.id">
                                <input class="form-control" type="number" (keyup)="updateRate($event, item.id)" [value]="item.rate" />
                            </ng-container>
                        </td>
                        <td>
                            <button class="btn btn-link" title="Edit Service Provider" (click)="onServiceProviderServiceTypeEditClicked(item)">
                                <i class="fa fa-pencil"></i>
                            </button>
                            <button class="btn btn-link" title="Delete Service Type From Service Provider" (click)="onServiceProviderServiceTypeDeleted(item.id)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot *ngIf="this.serviceProviderServiceTypes.length === 0">
                    <tr>
                        <td colspan="7" style="text-align: center;">No Service Types have been added to this Service Provider</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>
