<div class="container-fluid" style="max-height: 85vh;">

    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Account Confirmation</h1>
    </div>

    <div class="row">

        <!-- Earnings (Monthly) Card Example -->
        <div class="col-xl-12 col-8 mb-4">
            <div class="card shadow h-100 py-2">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <p> Your Account has been succesfully confirmed</p>
                    </div>
                </div>

            </div>
        </div>

    </div>
    <div class="row" *ngIf="!confirmationSuccess">

        <!-- Earnings (Monthly) Card Example -->
        <div class="col-xl-12 col-8 mb-4">
            <div class="card shadow h-100 py-2">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <p> Your Account has not been succesfully confirmed</p>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>