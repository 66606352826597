import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceProviderCreateComponent } from './service-provider-create/service-provider-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceProviderManagementComponent } from './service-provider-management.component';
import { SharedModule } from '../shared/shared.module';
import { ServiceProviderEditComponent } from './service-provider-edit/service-provider-edit.component';
import { ServiceProviderManageUserComponent } from './service-provider-manage-user/service-provider-manage-user.component';
import { ServiceProviderManageUserPasswordComponent } from './service-provider-manage-user-password/service-provider-manage-user-password.component';
import { ServiceProviderManageServicesComponent } from './service-provider-manage-services/service-provider-manage-services.component';
import { ServiceTypeCreateComponent } from './service-type-create/service-type-create.component';



@NgModule({
  declarations: [ServiceProviderCreateComponent,ServiceProviderManagementComponent, ServiceProviderEditComponent, ServiceProviderManageUserComponent, ServiceProviderManageUserPasswordComponent, ServiceProviderManageServicesComponent, ServiceTypeCreateComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class ServiceProviderModule { }
