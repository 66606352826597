import { AfterViewInit, Component, HostListener, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { BookingService } from "src/app/booking/booking.service";
import { ICustomerBooking } from "src/app/shared/models/customerBooking";
import { IGetBooking } from "src/app/shared/models/getBooking";
import { IServiceProviderBooking } from "src/app/shared/models/serviceProviderBooking";
import { UserService } from "src/app/shared/services/UserService";
import { HomeService } from "../home.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { SignalrService } from "src/app/services/signalr.service";

interface Country {
  id?: number;
  name: string;
  flag: string;
  area: number;
  population: number;
}

const COUNTRIES: Country[] = [
  {
    name: "Russia",
    flag: "f/f3/Flag_of_Russia.svg",
    area: 17075200,
    population: 146989754,
  },
  {
    name: "France",
    flag: "c/c3/Flag_of_France.svg",
    area: 640679,
    population: 64979548,
  },
  {
    name: "Germany",
    flag: "b/ba/Flag_of_Germany.svg",
    area: 357114,
    population: 82114224,
  },
  {
    name: "Portugal",
    flag: "5/5c/Flag_of_Portugal.svg",
    area: 92090,
    population: 10329506,
  },
  {
    name: "Canada",
    flag: "c/cf/Flag_of_Canada.svg",
    area: 9976140,
    population: 36624199,
  },
  {
    name: "Vietnam",
    flag: "2/21/Flag_of_Vietnam.svg",
    area: 331212,
    population: 95540800,
  },
  {
    name: "Brazil",
    flag: "0/05/Flag_of_Brazil.svg",
    area: 8515767,
    population: 209288278,
  },
  {
    name: "Mexico",
    flag: "f/fc/Flag_of_Mexico.svg",
    area: 1964375,
    population: 129163276,
  },
  {
    name: "United States",
    flag: "a/a4/Flag_of_the_United_States.svg",
    area: 9629091,
    population: 324459463,
  },
  {
    name: "India",
    flag: "4/41/Flag_of_India.svg",
    area: 3287263,
    population: 1324171354,
  },
  {
    name: "Indonesia",
    flag: "9/9f/Flag_of_Indonesia.svg",
    area: 1910931,
    population: 263991379,
  },
  {
    name: "Tuvalu",
    flag: "3/38/Flag_of_Tuvalu.svg",
    area: 26,
    population: 11097,
  },
  {
    name: "China",
    flag: "f/fa/Flag_of_the_People%27s_Republic_of_China.svg",
    area: 9596960,
    population: 1409517397,
  },
];

@Component({
  selector: "app-booking-list",
  templateUrl: "./booking-list.component.html",
  styleUrls: ["./booking-list.component.scss"],
})
export class BookingListComponent implements OnInit, AfterViewInit {
  myControl = new FormControl();
  options: Array<ICustomerBooking>;
  filteredOptions: Observable<Array<ICustomerBooking>>;
  public role = "";
  public userId = "";
  page = 1;
  pageSize = 4;
  collectionSize = 0;
  countries: Country[];
  public customerBookings = Array<ICustomerBooking>();
  public customerBookingPerPage = Array<ICustomerBooking>();
  public selectedBooking: ICustomerBooking;
  public selectedBookingId = 0;
  public serviceProviderBookings = Array<IServiceProviderBooking>();
  public serviceProviderBookingPerPage = Array<IServiceProviderBooking>();
  public currentServiceProviderId = 0;
  public canShowCancel = false;
  public bookingIdToCancel = 0;
  closeResult = '';

  innerWidth: number;

  constructor(
    private userService: UserService,
    private homeService: HomeService,
    private bookingService: BookingService,
    private modalService: NgbModal,
    private http: HttpClient,
    public signalRService: SignalrService,
  ) {
    this.userService.OnUserLoggedIn.subscribe(() => {
      this.role = this.userService.getRole();
      this.userId = this.userService.getUserId();
      if (this.userId || this.userId !== "") {
        if (this.role === "Customer") {
          this.homeService.getUserBookings(this.userId).subscribe((res) => {
            this.customerBookings = res as ICustomerBooking[];
            this.options = res as ICustomerBooking[];
            this.selectedBooking = this.customerBookings[0];
            const startBookingDate = new Date(
              this.selectedBooking.startTime.toString()
            );
            const dateNow = new Date();
            if (startBookingDate > dateNow) {
              this.canShowCancel = true;
            } else {
              this.canShowCancel = false;
            }
            this.filteredOptions = this.myControl.valueChanges.pipe(
              startWith(""),
              map((value) => this._filter(value))
            );
            this.collectionSize = this.customerBookings.length;
            this.customerBookingPerPage = this.customerBookings
              .map((booking, i) => ({ id: i + 1, ...booking }))
              .slice(
                (this.page - 1) * this.pageSize,
                (this.page - 1) * this.pageSize + this.pageSize
              );
          });
        } else if (this.role === "ServiceProvider") {
          this.currentServiceProviderId =
            this.userService.getServiceProviderId() as any;
          this.homeService
            .getServiceProviderBookings(this.currentServiceProviderId)
            .subscribe((res) => {
              this.serviceProviderBookings = res as IServiceProviderBooking[];
              this.serviceProviderBookingPerPage.forEach((x) => {});
              this.collectionSize = this.serviceProviderBookings.length;
              this.serviceProviderBookingPerPage = this.serviceProviderBookings
                .map((booking, i) => ({ id: i + 1, ...booking }))
                .slice(
                  (this.page - 1) * this.pageSize,
                  (this.page - 1) * this.pageSize + this.pageSize
                );
            });
        }
      }
    });
    this.userService.OnUserLoggedOut.subscribe(() => {
      this.role = this.userService.getRole();
      this.userId = this.userService.getUserId();
    });
    if (this.role === "Customer") {
      this.refreshBookingsCustomer();
    } else if (this.role === "ServiceProvider") {
      this.refreshBookingsServiceProvider();
    }
  }

  async ngOnInit() {
    this.role = this.userService.getRole();
    this.userId = this.userService.getUserId();
    this.innerWidth = window.innerWidth;
    await this.signalRService.startConnection(this.userService.getServiceProviderId() as any);
    await this.signalRService
      .addTransferOrderDataListener(this.userService.getServiceProviderId() as any)
      .finally();
    this.startHttpRequest(this.userService.getServiceProviderId() as any);
    
  }


  private startHttpRequest = (serviceProviderId: number) => {
    this.http
      .get(environment.apiUrl + 'booking?id=' + serviceProviderId)
      .subscribe((res) => {
        console.log(res);
      });

    console.log(this.signalRService.data);
  };
  
  ngAfterViewInit(): void {
    if (this.userId || this.userId !== "") {
      if (this.role === "Customer") {
        this.homeService.getUserBookings(this.userId).subscribe((res) => {
          this.customerBookings = res as ICustomerBooking[];
          console.log(this.customerBookings);
          this.options = res as ICustomerBooking[];
          this.selectedBooking = this.customerBookings[0];
          const startBookingDate = new Date(
            this.selectedBooking.startTime.toString()
          );
          const dateNow = new Date();
          if (startBookingDate > dateNow) {
            this.canShowCancel = true;
          } else {
            this.canShowCancel = false;
          }
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(""),
            map((value) => this._filter(value))
          );
          this.collectionSize = this.customerBookings.length;
          this.customerBookingPerPage = this.customerBookings
            .map((booking, i) => ({ id: i + 1, ...booking }))
            .slice(
              (this.page - 1) * this.pageSize,
              (this.page - 1) * this.pageSize + this.pageSize
            );
        });
      } else if (this.role === "ServiceProvider") {
        this.currentServiceProviderId =
          this.userService.getServiceProviderId() as any;
        this.homeService
          .getServiceProviderBookings(this.currentServiceProviderId)
          .subscribe((res) => {
            this.serviceProviderBookings = res as IServiceProviderBooking[];
            this.collectionSize = this.serviceProviderBookings.length;
            this.serviceProviderBookingPerPage = this.serviceProviderBookings
              .map((booking, i) => ({ id: i + 1, ...booking }))
              .slice(
                (this.page - 1) * this.pageSize,
                (this.page - 1) * this.pageSize + this.pageSize
              );
          });
      }
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    console.log(this.innerWidth);
  }

  refreshBookingsCustomer() {
    this.customerBookingPerPage = this.customerBookings
      .map((booking, i) => ({ id: i + 1, ...booking }))
      .slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      );
  }

  bookingSelected() {
    this.selectedBookingId = this.options.filter(
      (SP) => SP.dropdownDisplayName === this.myControl.value
    )[0].bookingId;
    this.selectedBooking = this.customerBookings.filter(
      (b) => b.bookingId === this.selectedBookingId
    )[0];
    const startBookingDate = new Date(
      this.selectedBooking.startTime.toString()
    );
    const dateNow = new Date();
    if (startBookingDate > dateNow) {
      this.canShowCancel = true;
    } else {
      this.canShowCancel = false;
    }
  }

  setSelectedBookingId(bookingId: number) {
    this.selectedBooking = this.customerBookings.filter(
      (b) => b.bookingId === bookingId
    )[0];
    const startBookingDate = new Date(
      this.selectedBooking.startTime.toString()
    );
    const dateNow = new Date();
    if (startBookingDate > dateNow) {
      this.canShowCancel = true;
    } else {
      this.canShowCancel = false;
    }
  }

  refreshBookingsServiceProvider() {
    this.serviceProviderBookingPerPage = this.serviceProviderBookings
      .map((booking, i) => ({ id: i + 1, ...booking }))
      .slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      );
  }

  private _filter(value: string): Array<ICustomerBooking> {
    const filterValue = value.toLowerCase();
    console.log(this.options);
    return this.options.filter((option) =>
      option.dropdownDisplayName
        .toLowerCase()
        .replace(/ /g, "")
        .includes(filterValue.toLowerCase().replace(/ /g, ""))
    );
  }

  cancelBooking(bookingId) {
    let getBooking = new IGetBooking();
    getBooking.bookingId = bookingId;
    this.bookingService.cancelBooking(getBooking).subscribe((res) => {
      if (res) {
        this.serviceProviderBookings = this.serviceProviderBookings.filter(
          (b) => {
            return b.bookingId != bookingId;
          }
        );
        this.refreshBookingsServiceProvider();
        this.reloadCurrentPage();
      }
    });
  }

  reloadCurrentPage() {
    window.location.reload();
  }

  showCancel(booking: ICustomerBooking) {
    const startBookingDate = new Date(booking.startTime.toString());
    const dateNow = new Date();
    if (startBookingDate > dateNow) {
      return true;
    } else {
      return false;
    }
  }

  open(content, bookingIdToCancel:number) {
    this.bookingIdToCancel = bookingIdToCancel; 
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  public resetBookingIdToCancel(){
    this.bookingIdToCancel = 0;
  }

  public isBookingActive(booking: IServiceProviderBooking): boolean {
    return this.isDateInPast(booking.startTime) && this.isDateInFuture(booking.endTime);
}

isDateInFuture(date: Date): boolean {
    const now = new Date();
    const dateInput = new Date(date); // Directly using the date object or a valid date string
    return dateInput > now;
}

isDateInPast(date: Date): boolean {
    const now = new Date();
    const dateInput = new Date(date); // Directly using the date object or a valid date string
    return dateInput < now;
}
}
