import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BookingService } from 'src/app/booking/booking.service';
import { INextBooking } from 'src/app/shared/models/nextBookingViewModel';
import { IServiceProviderDetails } from 'src/app/shared/models/serviceProviderDetails';
import { UserService } from 'src/app/shared/services/UserService';

@Component({
  selector: 'app-next-booking',
  templateUrl: './next-booking.component.html',
  styleUrls: ['./next-booking.component.scss']
})
export class NextBookingComponent implements OnInit {
  public nextBooking: INextBooking = new INextBooking();


  constructor(private bookingService: BookingService, private userService: UserService) {
    this.bookingService.OnNextBookingCompleted.subscribe(() => {
      this.nextBooking.bookingId = 0;
    });
  }

  ngOnInit(): void {
    this.nextBooking.serviceProviderDetails = new IServiceProviderDetails();
    let userId = this.userService.getUserId();
    if (userId) {
      console.log(this.nextBooking.startTime);
      this.bookingService.getNextBooking(userId).subscribe(res => {
        this.nextBooking = (res as INextBooking);

        console.log(this.nextBooking);

        this.bookingService.OnNextBookingLoaded.emit(this.nextBooking.startTime);

      });
    }

  }

}
