import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IConfirmEmail } from '../shared/models/confirmEmail';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  confirmEmailAddress(confirmEmail: IConfirmEmail) {
    return this.http.post(this.baseUrl + 'usermanager/confirmemail', confirmEmail);
  }
}
