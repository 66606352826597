<ng-container *ngIf="customerBookingPerPage.length===0 && this.role === 'Customer'">
  <p>You have not made any bookings yet.</p>
</ng-container>
<ng-container *ngIf="this.role === 'Customer' && customerBookingPerPage.length>0 && innerWidth < 1200">
  <div class="row no-gutters align-items-center">
    <div class="col mr-2">
      <form class="form-group">
        <mat-form-field class="example-full-width" style="width: 300px;">
          <input type="text" placeholder="Search here for history of bookings" aria-label="Number" matInput
            [formControl]="myControl" [matAutocomplete]="auto">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="bookingSelected()">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.dropdownDisplayName">
              {{option.dropdownDisplayName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
  </div>
  <h4 class="mb-3">Booking at {{this.selectedBooking.serviceProviderName}}</h4>
  <ul class="list-unstyled">
    <li>
      <div class="row" style="margin-top: 10px;">
        <div class="col-xl-4 col-4" style="font-weight: bold;">Services Booked</div>
        <div class="col-xl-1 col-1" style="font-weight: bold;">:</div>
        <div class="col-xl-7 col-6">
          <span> {{this.selectedBooking.bookedServices}}</span>
        </div>
      </div>
    </li>

    <li>
      <div class="row" style="margin-top: 10px;">
        <div class="col-xl-4 col-4" style="font-weight: bold;">Date</div>
        <div class="col-xl-1 col-1" style="font-weight: bold;">:</div>
        <div class="col-xl-7 col-6">
          <span> {{this.selectedBooking.bookingDate | date}}</span>
        </div>
      </div>
    </li>

    <li>
      <div class="row" style="margin-top: 10px;">
        <div class="col-xl-4 col-4" style="font-weight: bold;">Start Time</div>
        <div class="col-xl-1 col-1" style="font-weight: bold;">:</div>
        <div class="col-xl-7 col-6">
          <span> {{this.selectedBooking.startTime | date:'shortTime' }}</span>
        </div>
      </div>
    </li>
    <li>
      <div class="row" style="margin-top: 10px;">
        <div class="col-xl-4 col-4" style="font-weight: bold;">End Time</div>
        <div class="col-xl-1 col-1" style="font-weight: bold;">:</div>
        <div class="col-xl-7 col-6">
          <span> {{this.selectedBooking.endTime | date:'shortTime' }}</span>
        </div>
      </div>
    </li>
    <li style="margin-top: 10px !important; align-content: flex-end;" *ngIf="this.canShowCancel">
      <div class="row" style="text-align:right">
        <button style="margin-left: 10px;" class="btn btn-primary"
          (click)="open(cancel,this.selectedBooking.bookingId)">Cancel</button>
      </div>
    </li>
  </ul>
</ng-container>
<ng-container *ngIf="this.role === 'Customer' && innerWidth >1200 && customerBookingPerPage.length>0">
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Services Booked</th>
        <th scope="col">Service Provider</th>
        <th scope="col">Date</th>
        <th scope="col">Start Time</th>
        <th scope="col">End Time</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let customerBooking of customerBookingPerPage">
        <th>{{ customerBooking.bookedServices }}</th>
        <td>{{customerBooking.serviceProviderName}}</td>
        <td>{{ customerBooking.bookingDate | date}}</td>
        <td>{{ customerBooking.startTime | date:'shortTime' }}</td>
        <td>{{ customerBooking.endTime | date:'shortTime' }}</td>
        <td>
          <div class="row" style="text-align:right" *ngIf="showCancel(customerBooking)">
            <button style="margin-left: 10px;" class="btn btn-primary"
              (click)="open(cancel,customerBooking.bookingId)">Cancel</button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="d-flex justify-content-between p-2">
    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
      (pageChange)="refreshBookingsCustomer()">
    </ngb-pagination>

    <!-- <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshBookings()">
      <option [ngValue]="2">2 items per page</option>
      <option [ngValue]="4">4 items per page</option>
      <option [ngValue]="6">6 items per page</option>
    </select> -->
  </div>
</ng-container>
<ng-container *ngIf="this.role ==='ServiceProvider' && signalRService.data && innerWidth>1200">
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Registration</th>
        <th scope="col">Services Booked</th>
        <th scope="col">Date</th>
        <th scope="col">Start Time</th>
        <th scope="col">End Time</th>
        <!-- <th scope="col">Action</th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let serviceProviderBooking of signalRService.data" [ngStyle]="{'background-color': this.isBookingActive(serviceProviderBooking) ? 'lightblue':''}">
        <th>{{ serviceProviderBooking.registration }}</th>
        <td>{{serviceProviderBooking.bookedServices}}</td>
        <td >{{ serviceProviderBooking.bookingDate | date}}</td>
        <td>{{ serviceProviderBooking.startTime | date:'shortTime' }}</td>
        <td>{{ serviceProviderBooking.endTime | date:'shortTime'}}</td>
        <!-- <td style="text-align: center;">
            <i class="fa fa-trash icon" (click)="cancelBooking(serviceProviderBooking.bookingId)" title=" Cancel Booking"*ngIf="serviceProviderBooking.isCashBooking"></i>
        </td> -->
      </tr>
    </tbody>
  </table>


</ng-container>

<ng-template #cancel let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Booking Cancellation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        Are you sure you want to cancel your booking (T&C's apply)?
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')"
      (mouseup)="cancelBooking(this.bookingIdToCancel)">Yes</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')" (mouseup)="resetBookingIdToCancel()">No</button>
  </div>

</ng-template>
