import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { IForgotPassword, IUpdatePassword, IUpdatePasswordRequest } from 'src/app/shared/models/updatePassword';
import { IUser } from 'src/app/shared/models/user';
import { UserService } from 'src/app/shared/services/UserService';
import { AccountService } from '../account.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  loginForm: FormGroup;
  public errors : string;

  @Output() CloseModal = new EventEmitter();
  @Output() ShowRegisterForm = new EventEmitter();
  @Output() ShowLoginForm = new EventEmitter();

  constructor(private accountService: AccountService, private userService:UserService) { }

  ngOnInit(): void {
    this.createLoginForm();
  }

  public closeLoginModal() {
    this.CloseModal.emit();
  }

  public showRegisterForm(){
    this.ShowRegisterForm.emit();
  }

  public checkPasswords(){
    console.log(this.loginForm.value);
  }
  public showLoginForm() {
    this.ShowLoginForm.emit();
  }

  createLoginForm() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators
        .pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')]),
      password: new FormControl('', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]),
      confirmPassword: new FormControl('', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')])
    });
  }

  onSubmit() {

    const oldrgform = (this.loginForm.value as IForgotPassword);

    let updatePasswordRequest: IUpdatePasswordRequest = {
      password: oldrgform.password,
      email: oldrgform.email,
      userId: null
    };

    this.accountService.updatePassword(updatePasswordRequest).subscribe(user => {
      this.ShowLoginForm.emit();
    },
      error => {
        console.log(error.error.errors);
        this.errors = error.error.errors.Login;
      }
    );
  }
}
