import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AccountService } from './account/account.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'BookNSplash';
  backroundImagePath = ' ..\/background.png';
  backgroundSrc: any;

  constructor(private accountService: AccountService, private sanitizer: DomSanitizer,private router:Router) { }

  ngOnInit(): void {
    
    this.backgroundSrc =
      this.sanitizer.bypassSecurityTrustStyle(`url(${this.backroundImagePath}) no-repeat`);
    //this.loadCurrentUser();
  }

  loadCurrentUser() {
    const token = localStorage.getItem('token');
    this.accountService.loadCurrentUser(token).subscribe(() => {
      console.log('loaded user');
    }, error => {
      console.log(error);
    });
  }

}
