<div class="container-fluid" style="overflow: auto; max-height: 85vh;" *ngIf="this.confirmedBooking">
    <div class="row">
        <!-- Area Chart -->
        <div class="col-xl-12 col-12">
          <div class="card shadow mb-4">
            <!-- Card Header - Dropdown -->
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h2 class="m-0 font-weight-bold text-primary">{{this.confirmedBooking.serviceProviderDetails.serviceProviderName}}</h2>
            </div>
            <!-- Card Body -->
            <div class="card-body">
              <ul class="list-unstyled">
                <li>
                  <div class="row">
                    <div class="col-xl-2 col-2" style="text-align: center;"><i class="fa fa-map-marker fa-2x"></i></div>
                    <div class="col-xl-10 col-10">
                      <span> {{this.confirmedBooking.serviceProviderDetails.fullAddress}}</span><br>
                      <span> {{this.confirmedBooking.serviceProviderDetails.region}}</span><br>
                      <span> {{this.confirmedBooking.serviceProviderDetails.city}}</span><br>
                      <span> {{this.confirmedBooking.serviceProviderDetails.postalCode}}</span>
                    </div>
                  </div>
                </li>
    
                <li>
                  <div class="row" style="margin-top: 10px;">
                    <div class="col-xl-2 col-2" style="text-align: center;"><i class="fa fa-phone mt-4 fa-2x"
                        style="margin-top: 0px !important;"></i></div>
                    <div class="col-xl-10 col-10">
                      <span> {{this.confirmedBooking.serviceProviderDetails.contactNumberOne}}</span>
                      <span> {{this.confirmedBooking.serviceProviderDetails.contactNumberTwo}}</span>
                    </div>
                  </div>
                </li>
    
                <li>
                  <div class="row" style="margin-top: 10px;">
                    <div class="col-xl-2 col-2" style="text-align: center;">
                      <i class="fa fa-envelope mt-4 fa-2x" style="margin-top: 0px !important;"></i>
                    </div>
                    <div class="col-xl-10 col-10">
                      <span> {{this.confirmedBooking.serviceProviderDetails.email}}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">

        <!-- Area Chart -->
        <div class="col-xl-12 col-12">
          <div class="card shadow mb-4">
            <!-- Card Header - Dropdown -->
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h4 style="word-wrap: break-word;max-width: 300px;" class="m-0 font-weight-bold text-primary">Your booking payment has been processed</h4>
            </div>
            <!-- Card Body -->
            <div class="card-body">
              <h6 *ngIf="innerWidth<1200" style="max-width:fit-content" class="m-0 font-weight-bold text-primary mb-4">Your confirmed booking details are below :</h6>
              <h6 *ngIf="innerWidth>1200" class="m-0 font-weight-bold text-primary mb-4">Your confirmed booking details are below :</h6>
              <div>
                <div class="row">
                  <div class="col-xl-8 col-8">
                    <label>Booking Date:</label>
                  </div>
                  <div class="col-xl-4 col-4">
                    {{this.confirmedBooking.createdBookingResponse.bookingDate | date}}
                  </div>
                </div>
              </div>
              <div>
                <div class="row">
                  <div class="col-xl-8 col-8">
                    <label>Booking Start Time</label>
                  </div>
                  <div class="col-xl-4 col-4">
                    {{this.confirmedBooking.createdBookingResponse.bookingStartTime | date:'shortTime'}}
                  </div>
                </div>
              </div>
              <div>
                <div class="row">
                  <div class="col-xl-8 col-8">
                    <label>Booking End Time</label>
                  </div>
                  <div class="col-xl-4 col-4">
                    {{this.confirmedBooking.createdBookingResponse.bookingEndTime | date:'shortTime'}}
                  </div>
                </div>
              </div>
              <div>
                <div class="row">
                  <div class="col-xl-8 col-8">
                    <label>Booking Total Cost</label>
                  </div>
                  <div class="col-xl-4 col-4">
                    R {{this.confirmedBooking.createdBookingResponse.totalCost}}
                  </div>
                </div>
              </div>
              <div class="form-group row float-right">
                <div class="col-xl-12 col-12">
                  <button class="btn btn-info" (click)="onBackToDashboardClicked()"><i class="fas fa-columns"></i> Back To Dashboard</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>