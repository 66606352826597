import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { TextInputComponent } from './components/text-input/text-input.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCommonModule, MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { UserService } from './services/UserService';
import { CountDownTimerComponent } from './components/count-down-timer/count-down-timer.component';
import { EventHub } from './events/EventHub';
import { MobileTextInputComponent } from './components/mobile-text-input/mobile-text-input.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';


@NgModule({
  declarations: [TextInputComponent,SafeHtmlPipe, CountDownTimerComponent, MobileTextInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSmartModalModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatCommonModule,
    MatOptionModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatInputModule,
    MatSlideToggleModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgxSmartModalModule,
    TextInputComponent,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatCommonModule,
    MatOptionModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatInputModule,
    MatSlideToggleModule,
    SafeHtmlPipe,
    CountDownTimerComponent,
    MobileTextInputComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule
  ],
  providers:[UserService,EventHub]
})
export class SharedModule { }
