import { Time } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAddServiceProviderServiceType } from 'src/app/shared/models/addServiceProviderServiceType';
import { IServiceProvderServiceType } from 'src/app/shared/models/serviceProviderServiceType';
import { IServiceType } from 'src/app/shared/models/serviceType';
import { IUpdateServiceProviderServiceType } from 'src/app/shared/models/updateServiceProviderServiceType';
import { ServiceProviderService } from '../service-provider.service';

@Component({
  selector: 'app-service-provider-manage-services',
  templateUrl: './service-provider-manage-services.component.html',
  styleUrls: ['./service-provider-manage-services.component.scss']
})
export class ServiceProviderManageServicesComponent implements OnInit {
  public serviceTypes: Array<IServiceType> = [];
  public serviceProviderServiceTypes: Array<IServiceProvderServiceType> = [];
  public isAddServiceTypeActive = false;
  public isEditRateDurationActive = false;
  public serviceTypeId = 0;
  public inputRate = 0;
  public inputDuration: Time;
  public inputWashDuration: Time;
  public inputDryDuration: Time;
  public selectedServiceProviderServiceTypeId = 0;

  @Output() onCancel = new EventEmitter();
  @Input() serviceProviderId = 0;
  @Input() innerWidth: number;

  constructor(private serviceProviderService: ServiceProviderService) { }

  ngOnInit(): void {
    this.onInit();
  }

  public onInit() {
    this.serviceProviderService.getServiceProviderServiceTypes(this.serviceProviderId).subscribe((res: any) => {
      if ((res as Array<any>).length > 0) {
        this.serviceProviderServiceTypes = (res as Array<IServiceProvderServiceType>);
        this.serviceProviderService.getServiceTypes().subscribe(res2 => {
          this.serviceTypes = (res2 as Array<IServiceType>);
        });
      } else {
        this.serviceProviderService.getServiceTypes().subscribe(res2 => {
          if ((res2 as Array<any>).length > 0) {
            this.serviceTypes = (res2 as Array<IServiceType>);
          }
        });
      }
    });
  }

  public updateDuration(event: any, itemId: number) {
    const duration = event.target.value;
    const item = this.serviceProviderServiceTypes.find(i => i.id === itemId);
    if (item) {
        item.duration = duration;
    }
  }

  public updateWashDuration(event: any, itemId: number) {
    const washDuration = event.target.value;
    const item = this.serviceProviderServiceTypes.find(i => i.id === itemId);
    if (item) {
        item.washDuration = washDuration;
    }
  }

  public updateDryDuration(event: any, itemId: number) {
    const dryDuration = event.target.value;
    const item = this.serviceProviderServiceTypes.find(i => i.id === itemId);
    if (item) {
        item.dryDuration = dryDuration;
    }
  }

  public updateRate(event: any, itemId: number) {
    const rate = event.target.value;
    const item = this.serviceProviderServiceTypes.find(i => i.id === itemId);
    if (item) {
        item.rate = rate;
    }
  }

  public onServiceProviderServiceTypeEditClicked(serviceProviderServiceType: IServiceProvderServiceType) {
    this.isEditRateDurationActive = true;
    this.selectedServiceProviderServiceTypeId = serviceProviderServiceType.id;
    this.inputDuration = serviceProviderServiceType.duration;
    this.inputWashDuration = serviceProviderServiceType.washDuration;
    this.inputDryDuration = serviceProviderServiceType.dryDuration;
    this.inputRate = serviceProviderServiceType.rate;
    this.serviceTypeId = serviceProviderServiceType.serviceTypeId;
  }

  public onAddNewServiceType() {
    const addServiceProviderServiceType: IAddServiceProviderServiceType = {
      serviceProviderId: this.serviceProviderId,
      serviceTypeId: this.serviceTypeId,
      rate: this.inputRate,
      duration: this.inputDuration,
      washDuration: this.inputWashDuration,
      dryDuration: this.inputDryDuration
    };
    this.serviceProviderService.addServiceProviderServiceType(addServiceProviderServiceType).subscribe(res => {
      this.serviceProviderServiceTypes = (res as Array<IServiceProvderServiceType>);
      this.isAddServiceTypeActive = false;
    });
  }

  public onSaveEditDurationRate() {
    const updateServiceProviderServiceType: IUpdateServiceProviderServiceType = {
      serviceProviderServiceTypeId: this.selectedServiceProviderServiceTypeId,
      rate: this.inputRate,
      duration: this.inputDuration,
      washDuration: this.inputWashDuration,
      dryDuration: this.inputDryDuration
    };
    this.serviceProviderService.updateServiceProviderServiceType(updateServiceProviderServiceType).subscribe(res => {
      let serviceProviderToUpdate = this.serviceProviderServiceTypes.find(x => x.id === this.selectedServiceProviderServiceTypeId);
      if (serviceProviderToUpdate) {
        serviceProviderToUpdate.duration = this.inputDuration;
        serviceProviderToUpdate.washDuration = this.inputWashDuration;
        serviceProviderToUpdate.dryDuration = this.inputDryDuration;
        serviceProviderToUpdate.rate = this.inputRate;
      }
      this.isEditRateDurationActive = false;
      this.selectedServiceProviderServiceTypeId = 0;
    });
  }

  public onServiceProviderServiceTypeDeleted(serviceProviderServiceTypeId: number) {
    this.serviceProviderService.deleteServiceProviderServiceType(serviceProviderServiceTypeId).subscribe(res => {
      if (res) {
        this.serviceProviderServiceTypes = this.serviceProviderServiceTypes.filter(t => t.id !== serviceProviderServiceTypeId);
      }
    });
  }

  public showAddServiceTypeTable() {
    this.isAddServiceTypeActive = true;
  }

  public onAddNewServiceTypeCancel() {
    this.isAddServiceTypeActive = false;
  }

  public onSaveDurationRateCancel() {
    this.isEditRateDurationActive = false;
    this.selectedServiceProviderServiceTypeId = 0;
  }
}
