<!-- Begin Page Content -->
<div class="container-fluid" style="max-height: 85vh;">
  <!-- Content Row -->
  <div class="row" *ngIf="loggedInRole !=='ServiceProvider'">

    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-12 col-12 mb-4">
      <div class="card shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <form class="form-group">
                <mat-form-field class="example-full-width">
                  <input type="text" placeholder="Search for a Service Provider" aria-label="Number" matInput
                    [formControl]="myControl" [matAutocomplete]="auto" >
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name" [disabled]="!option.isOnline">
                      {{option.name}} {{option.isOnline ? '':' (Offline)'}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <button style="margin-left: 10px;" class="btn btn-primary" (click)="viewServiceProviderDetails()"><i
                  class="fa fa-search"></i> View</button>
              </form>
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
  <div class="row" *ngIf="loggedInRole ==='ServiceProvider'">

    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-12 col-4 mb-4">
      <div class="card shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col-6">
              <button style="margin-left: 10px;" class="btn btn-primary"
                (click)="viewCurrentServiceProviderDetails()"><i class="fa fa-bookmark"></i> Book Now</button>
              <!-- <p>For walk in booking, Cash must be recorded</p> -->

            </div> 
            <div class="col-6">
                <mat-slide-toggle (change)="onlineOfflineToggled($event)" [checked]="this.isOnline"> Online</mat-slide-toggle>
            </div>        
          </div>

        </div>
      </div>
    </div>

    <div class="col-xl-12 col-4 mb-4">
      <div class="card shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">          
            <div class="col-auto">
              <button style="margin-left: 10px;" class="btn btn-primary"
                (click)="getReport()"><i class="fa fa-bookmark"></i> Download Booking Report</button>
              <!-- <p>For walk in booking, Cash must be recorded</p> -->
            </div>
            <div class="col-auto" style="margin-left: 20px;" *ngIf="!this.reportDownloadValid">
              <span class="text-danger">Please ensure that a valid date range is selected</span>
            </div>

            <div class="col-auto" style="margin-left: 20px;">
              <mat-form-field color="accent">
                <mat-label>Please select start date</mat-label>
                <input matInput [matDatepicker]="picker1" (dateChange)="startDateChanged($event)">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-auto" style="margin-left: 20px;">
              <mat-form-field color="accent">
                <mat-label>Please select end date</mat-label>
                <input matInput [matDatepicker]="picker2" (dateChange)="endDateChanged($event)">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>

  <!-- Content Row -->

  <div class="row" *ngIf="loggedInRole ==='ServiceProvider'">

    <!-- Area Chart -->
    <div class="col-xl-12 col-lg-12 col-xs-12">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary">List of Bookings</h6>
        </div>
        <!-- Card Body -->
        <div class="card-body">
          <div class="chart-area">
            <app-booking-list></app-booking-list>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="loggedInRole ==='Customer' && innerWidth > 1200">

    <!-- Area Chart -->
    <div class="col-xl-8 col-4">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary">List of Bookings</h6>
        </div>
        <!-- Card Body -->
        <div class="card-body">
          <div class="chart-area">
            <app-booking-list></app-booking-list>
          </div>
        </div>
      </div>
    </div>

    <!-- Pie Chart -->
    <div class="col-xl-4 col-4">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary">Next Booking</h6>
        </div>
        <div class="card-body">
          <div class="chart-area">
            <app-next-booking></app-next-booking>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="loggedInRole ==='Customer' && innerWidth < 1200">
    <!-- Pie Chart -->
    <div class="col-xl-12 col-12">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary">Next Booking</h6>
        </div>
        <div class="card-body" style="min-height: 420px;">
          <div class="chart-area">
            <app-next-booking></app-next-booking>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="loggedInRole ==='Customer' && innerWidth < 1200">

    <!-- Area Chart -->
    <div class="col-xl-12 col-12">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary">List of Bookings</h6>
        </div>
        <!-- Card Body -->
        <div class="card-body" style="min-height: 330px; padding: 0px; padding-left: 20px; padding-top: 10px;">
            <app-booking-list></app-booking-list>
        </div>
      </div>
    </div>
  </div>
</div>