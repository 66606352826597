import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith, timestamp } from 'rxjs/operators';
import { ServiceProviderService } from '../service-provider-management/service-provider.service';
import { IBookingReportRequest } from '../shared/models/BookingReportRequestBody';
import { IServiceProvider } from '../shared/models/serviceProvider';
import { UserService } from '../shared/services/UserService';
import { HomeService } from './home.service';
import { saveAs } from "file-saver";
import { IChangeServiceProviderStatus } from '../shared/models/changeServiceProviderStatus';
import { ServiceProviderDetailsService } from '../service-provider-details/service-provider-details.service';
import { IServiceProviderDetails } from '../shared/models/serviceProviderDetails';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  myControl = new FormControl();
  options: Array<IServiceProvider> = [];
  filteredOptions: Observable<Array<IServiceProvider>>;
  public selectedServiceProviderId = 0;
  public currentServiceProviderId = 0;
  public accordianClass: string = '';
  public loggedInRole: string = "";
  innerWidth: number;
  isOnline = false;
  reportStartDate:string;
  reportEndDate:string;
  reportDownloadValid = true;


  constructor(private serviceProviderService: ServiceProviderService, private router: Router, private userService: UserService, private homeService: HomeService, private serviceProviderDetailsService:ServiceProviderDetailsService) {
    this.userService.OnUserLoggedIn.subscribe(() => {
      this.getListOfServiceProviders();
      this.loggedInRole = this.userService.getRole();
      if (this.loggedInRole === "ServiceProvider") {
        this.currentServiceProviderId = (this.userService.getServiceProviderId() as any)
        this.serviceProviderDetailsService.getServiceProvider(this.currentServiceProviderId).subscribe(res => {
          var serviceProviderDetails = (res as IServiceProviderDetails);
          this.isOnline = serviceProviderDetails.isOnline;
        })
      }
    });
    this.userService.OnUserLoggedOut.subscribe(() => {
      this.loggedInRole = "";
      this.getListOfServiceProviders();
    })
  }
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.getListOfServiceProviders();
    this.loggedInRole = this.userService.getRole();
    if (this.loggedInRole === "ServiceProvider") {
      this.currentServiceProviderId = (this.userService.getServiceProviderId() as any)
      this.serviceProviderDetailsService.getServiceProvider(this.currentServiceProviderId).subscribe(res => {
        var serviceProviderDetails = (res as IServiceProviderDetails);
        this.isOnline = serviceProviderDetails.isOnline;
      })
    }
  }

  public getListOfServiceProviders() {
    this.serviceProviderService.getServiceProviders().subscribe((res: Array<IServiceProvider>) => {
      if (res && res.length > 0) {
        this.options = res;
        this.options = this.options.filter(sp => sp.active === true);
        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      }
    });
  }

  public changeClass() {
    if (this.accordianClass === '') {
      this.accordianClass = 'toggled';
    } else {
      this.accordianClass = '';
    }
  }

  private _filter(value: string): Array<IServiceProvider> {
    const filterValue = value.toLowerCase();
    console.log(this.options);
    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  public viewServiceProviderDetails() {
    if (this.myControl.value !== 'undefined' || this.myControl.value !== '') {
      this.selectedServiceProviderId = this.options.filter(SP => SP.name === this.myControl.value)[0].id;
    }
    console.log(this.selectedServiceProviderId);
    const navigationExtras: NavigationExtras = { state: { id: this.selectedServiceProviderId } };
    this.router.navigateByUrl(`/serviceproviderdetails/?id=${this.selectedServiceProviderId}`);
  }

  public viewCurrentServiceProviderDetails() {
    this.router.navigateByUrl(`/serviceproviderdetails/?id=${this.currentServiceProviderId}`);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    console.log(this.innerWidth);
  }

  public getReport() {
    this.reportDownloadValid = true;
    if(this.reportStartDate === undefined || this.reportEndDate === undefined){
      this.reportDownloadValid = false;
      return;
    }
    if(this.reportStartDate >= this.reportEndDate){
      this.reportDownloadValid = false;
      return;
    }
    let request: IBookingReportRequest = { id: this.currentServiceProviderId, reportStartDate:this.reportStartDate , reportEndDate:this.reportEndDate };
    this.homeService.getBookingReport(request).subscribe((res) => {
      if (res.content && res.content.size) {
        saveAs(res.content, 'BookingReport.csv');
      }
    });
  }

  public onlineOfflineToggled(event: any) {
    let request: IChangeServiceProviderStatus = { serviceProviderId: this.currentServiceProviderId,isOnline:event.checked };
    this.serviceProviderService.changeServiceProviderStatus(request).subscribe(res => {
        this.isOnline = res;
    });
  }

  public startDateChanged(event: MatDatepickerInputEvent<Date>){
    this.reportStartDate = event.value.toLocaleDateString();
    console.log(event.value);
  }

  public endDateChanged(event: MatDatepickerInputEvent<Date>){
    this.reportEndDate = event.value.toLocaleDateString();
  }

}
