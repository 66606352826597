import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SignalrService {
  public data: any[];
  public data$ = new BehaviorSubject<any[]>([]);
  private hubConnection: signalR.HubConnection;
  public startConnection = async (serviceProviderId: number) => {
    console.log(serviceProviderId);
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.baseUrl + "BookingSync", {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();
    await this.hubConnection
      .start()
      .then(() => console.log("Connection started"))
      .catch((err) => console.log("Error while starting connection: " + err));
  };

  public addTransferOrderDataListener = async (serviceProviderId: number) => {
    await this.hubConnection.on("transferbookingdata_" + serviceProviderId, (data) => {
      this.data = data;
      console.log(data);
      this.data$.next(data);
    });
  };
}