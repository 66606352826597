import { Component, HostListener, OnInit } from '@angular/core';
import { EventHub } from 'src/app/shared/events/EventHub';
import { UserService } from 'src/app/shared/services/UserService';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  public role = "";
  public toggleSideBarMobileClass ="toggled";
  innerWidth: number;

  constructor(private userService: UserService, private eventHub: EventHub) {
    this.userService.OnUserLoggedIn.subscribe(() => {
      this.role = this.userService.getRole();
    });
    this.userService.OnUserLoggedOut.subscribe(() => {
      this.role = "";
    });
    this.eventHub.toggleSideBarForMobile.subscribe(()=>{
      if (this.toggleSideBarMobileClass === '' ) {
        this.toggleSideBarMobileClass = 'toggled';
      }
      else {
        this.toggleSideBarMobileClass = '';
      }
      
    });
  }

  ngOnInit(): void {
    this.role = this.userService.getRole();
    this.innerWidth = window.innerWidth;
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    console.log(this.innerWidth);
  }

}
