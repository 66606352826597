import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IRegisterServiceProvider } from 'src/app/shared/models/registerServiceProviderModel';
import { ServiceProviderService } from '../service-provider.service';

@Component({
  selector: 'app-service-provider-edit',
  templateUrl: './service-provider-edit.component.html',
  styleUrls: ['./service-provider-edit.component.scss']
})
export class ServiceProviderEditComponent implements OnInit {

  SPForm: FormGroup;
  public serviceProviderInfo: IRegisterServiceProvider;

  @Input() serviceProviderId: number = 0;
  @Input() innerWidth: number;

  @Output() onCancel = new EventEmitter();
  @Output() onSuccessfullRegister = new EventEmitter();

  constructor(private fb: FormBuilder, private serviceProviderService: ServiceProviderService) { }

  ngOnInit(): void {
    console.log(this.serviceProviderId);
    this.createSPForm();
    if (this.serviceProviderId === 0) {
      this.createSPForm();
    } else {
      this.getServiceProviderUserInfo(this.serviceProviderId);
    }

  }

  getServiceProviderUserInfo(id: number) {
    this.serviceProviderService.getServiceProvider(id).subscribe(response => {
      this.serviceProviderInfo = (response as IRegisterServiceProvider);
      this.fillSPForm();
    }, error => {
      console.log(error);
    });
  }

  createSPForm() {
    this.SPForm = this.fb.group({
      fullAddress: [null, [Validators.required]],
      city: [null, [Validators.required]],
      region: [null, [Validators.required]],
      postalCode: [null, [Validators.required]],
      country: [null, [Validators.required]],
      serviceProviderName: [null, [Validators.required]],
      startTime: [null, [Validators.required]],
      endTime: [null, [Validators.required]],
      capacity: [null, [Validators.required]],
      isClosedOnHolidays: [null, [Validators.required]],
    });
  }

  fillSPForm() {
    this.SPForm = this.fb.group({
      fullAddress: [this.serviceProviderInfo.fullAddress, [Validators.required]],
      city: [this.serviceProviderInfo.city, [Validators.required]],
      region: [this.serviceProviderInfo.region, [Validators.required]],
      postalCode: [this.serviceProviderInfo.postalCode.toString(), [Validators.required]],
      country: [this.serviceProviderInfo.country, [Validators.required]],
      serviceProviderId: [this.serviceProviderId.toString(), [Validators.required]],
      serviceProviderName: [this.serviceProviderInfo.serviceProviderName, [Validators.required]],
      startTime: [this.serviceProviderInfo.startTime, [Validators.required]],
      endTime: [this.serviceProviderInfo.endTime, [Validators.required]],
      capacity: [this.serviceProviderInfo.capacity, [Validators.required]],
      isClosedOnHolidays: [this.serviceProviderInfo.isClosedOnHolidays, [Validators.required]],
    });
  }

  public onSubmit() {
    const oldrgform = (this.SPForm.value as IRegisterServiceProvider);

    const rgform = (this.SPForm.value as IRegisterServiceProvider);
    rgform.postalCode = (oldrgform.postalCode as number);
    console.log(rgform);
    this.serviceProviderService.update(this.SPForm.value).subscribe(response => {
      this.onSuccessfullRegister.emit();
    }, error => {
      console.log(error);
    });

}
}
