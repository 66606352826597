import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';
import crypto from 'crypto-js';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  @Input() CustomerName: string;
  @Input() amount: number;
  @Input() bookingId: number;
  constructor() { }

  ngOnInit(): void {
  }

  public returnSignatureForm() {
    const myData = [];
    // Merchant details
    myData["merchant_id"] = "10000100";
    myData["merchant_key"] = "46f0cd694581a";
    myData["return_url"] = "https://booknsplash.com/#/bookingsucceeded?id=" + this.bookingId;
    myData["cancel_url"] = "https://booknsplash.com/#/bookingfailed/?id=" + this.bookingId;;
    myData["notify_url"] = "http://www.yourdomain.co.za/notify_url";

    // Buyer details
    myData["name_first"] = "John";
    myData["name_last"] = "Doe";
    myData["email_address"] = "john@doe.com";

    // Transaction details
    myData["m_payment_id"] = "1234";
    myData["amount"] = this.amount.toString();
    myData["item_name"] = "Order#123";

    const generateSignature = (data, passPhrase = null) => {
      // Create parameter string
      let pfOutput = "";
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          if (data[key] !== "") {
            pfOutput += `${key}=${encodeURIComponent(data[key].trim()).replace(/%20/g, " + ")}&`
          }
        }
      }

      // Remove last ampersand
      let getString = pfOutput.slice(0, -1);
      if (passPhrase !== null) {
        getString += `&passphrase=${encodeURIComponent(passPhrase.trim()).replace(/%20/g, "+")}`;
      }
      const md5 = Md5.hashStr(getString);

      var md5Hash = crypto.MD5('crypto' + getString);
      return md5.toString();
    };
    // Generate signature
    myData["signature"] = generateSignature(myData);

    const pfHost = "sandbox.payfast.co.za";

    let htmlForm = `<form action="https://${pfHost}/eng/process" method="post">`;
    for (let key in myData) {
      if (myData.hasOwnProperty(key)) {
        const value = myData[key];
        if (value !== "") {
          htmlForm += `<input name="${key}" type="hidden" value="${value.trim()}" />`;
        }
      }
    }

    return htmlForm += '<button type="submit" class="btn btn-info"><i class="fa fa-plus"></i> Pay Now</button></form>';
  }
}