import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IConfirmedBookingResponse } from 'src/app/shared/models/confirmedBookingResponse';
import { IGetBooking } from 'src/app/shared/models/getBooking';
import { BookingService } from '../booking.service';

@Component({
  selector: 'app-booking-failed',
  templateUrl: './booking-failed.component.html',
  styleUrls: ['./booking-failed.component.scss']
})
export class BookingFailedComponent implements OnInit {

  public bookingId = 0;
  public canceledBooking: IConfirmedBookingResponse;

  constructor(private activatedRoute: ActivatedRoute, private bookingService: BookingService, private router:Router) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      const bookingId = params['id'];
      console.log(bookingId);
      this.cancelAndGetBookingDetails(bookingId);
    });
  }

  public cancelAndGetBookingDetails(bookingId:number) {
    console.log(bookingId);
    let getBooking: IGetBooking = new IGetBooking();
    getBooking.bookingId = bookingId;
    console.log(getBooking);
    this.bookingService.cancelBooking(getBooking).subscribe(res => {
      this.canceledBooking = (res as IConfirmedBookingResponse);
    });
  }

  public onBackToDashboardClicked(){
    this.router.navigateByUrl('/');
  }


}
