import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AsyncValidatorFn, Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AccountService } from '../account.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  public errors: string;
  public termsAndConditionsChecked = false;
  public type = 'password';

  @Output() CloseModal = new EventEmitter();

  @Output() ShowLoginForm = new EventEmitter();
  
  @Output() ShowForgotPassword = new EventEmitter();

  constructor(private fb: FormBuilder, private accountService: AccountService) { }

  ngOnInit() {
    this.createRegisterForm();
    this.termsAndConditionsChecked = false;
  }

  public closeModal() {
    this.CloseModal.emit();
  }

  public showLoginForm() {
    this.ShowLoginForm.emit();
  }
  public showForgotPassword(){
    this.ShowForgotPassword.emit();
  }

  createRegisterForm() {
    this.registerForm = this.fb.group({
      firstNames: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null,
        [Validators.required, Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')]
      ],
      password: [null, [Validators.required,Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      contactNumberOne: [null, [Validators.required]],
      registrationNumber: [null, [Validators.required]]
    });
  }

  onSubmit() {
    this.accountService.register(this.registerForm.value).subscribe(response => {
      this.ShowLoginForm.emit();
    }, error => {
      console.log(error);
      this.errors = error.error.errors.Error;
      console.log(this.errors);
    });
  }

  onChangeType(event) {
    this.type = event;
  }

  // validateEmailNotTaken(): AsyncValidatorFn {
  //   return control => {
  //     return timer(500).pipe(
  //       switchMap(() => {
  //         if (!control.value) {
  //           return of(null);
  //         }
  //         return this.accountService.checkEmailExists(control.value).pipe(
  //           map(res => {
  //             return res ? { emailExists: true } : null;
  //           })
  //         );
  //       })
  //     );
  //   };
  // }
}
