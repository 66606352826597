import { IController } from "./icontroller";
import { Injectable } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ModalController {

    private modalService: NgxSmartModalService;

    setProvider(provider: NgxSmartModalService) {
        this.modalService = provider;
    }

    openModal(name: string, dismissable: boolean = true, data: any = null) {
        const modal = this.modalService.getModal(name);
        if (data) {
            modal.setData(data, true);
        }
        modal.dismissable = dismissable;
        modal.open();
    }

    subscribeData(name: string) {
        return new Observable<any>(observer => {
            this.modalService.getModal(name).onDataAdded.subscribe((modal: NgxSmartModalComponent) => {
                const data = this.modalService.getModalData(name);
                observer.next(data);
            });
        });
    }

    subscribeOpen(name: string) {
        return new Observable<any>(observer => {
            this.modalService.getModal(name).onOpen.subscribe((modal: NgxSmartModalComponent) => {
                observer.next();
            });
        });
    }

    closeModal(name: string) {
        this.modalService.getModal(name).close();
    }
}