<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Login</h4>
    <div style="padding: 2px;">
        <button type="button" class="close" aria-label="Close" (click)="closeLoginModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="card-body p-0">
        <!-- Nested Row within Card Body -->
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Welcome Back!</h1>
                </div>
                <form class="user" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <app-text-input formControlName="email" [label]="'Email'"></app-text-input>
                    </div>
                    <div class="form-group">
                        <div class="form-group row">
                            <div class="col-10">
                                <app-text-input formControlName="password" [(type)]="type" [label]="'Password'"></app-text-input>
                            </div>
                            <div class="col-2">
                                <div style="padding: 10px;"><i class="fa fa-eye-slash" (click)="onChangeType(type ==='password'?'text':'password')"></i></div>
                            </div>
                        </div>
                    </div>
                    <ul class="text-danger list-unstyled" *ngIf="errors">
                        <li>
                            {{this.errors}}
                        </li>
                    </ul>
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <button class="btn btn-primary" type="submit">
                                <i class="fa fa-sign-in"></i>
                                Login
                            </button>
                        </div>
                    </div>
                </form>
                <hr>
                <div class="text-center">
                    <a class="small" href="javascript:void(0)" (click)="showForgotPassword()">Forgot Password?</a>
                </div>
                <div class="text-center">
                    <a class="small" href="javascript:void(0)" (click)="showRegisterForm()">Create an Account!</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
  </div> -->