import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IServiceType } from 'src/app/shared/models/serviceType';
import { ServiceProviderService } from '../service-provider.service';

@Component({
  selector: 'app-service-type-create',
  templateUrl: './service-type-create.component.html',
  styleUrls: ['./service-type-create.component.scss']
})
export class ServiceTypeCreateComponent implements OnInit {
  ServiceTypeForm: FormGroup;
  public serviceTypeInfo: IServiceType;
  constructor(private fb: FormBuilder, private serviceProviderService: ServiceProviderService) { }

  @Output() onCancel = new EventEmitter();
  @Output() onSuccessfullRegister = new EventEmitter();

  @Input() innerWidth: number;

  ngOnInit(): void {
    this.createServiceTypeForm();
  }

  createServiceTypeForm() {
    this.ServiceTypeForm = this.fb.group({
      name: [null, [Validators.required]],
      description: [null, [Validators.required]],
      type: [null, [Validators.required]]
    });
  }

  public onSubmit() {
    const stForm = (this.ServiceTypeForm.value as IServiceType);
    console.log(stForm);
    this.serviceProviderService.addServiceType(stForm).subscribe(response => {
      this.onSuccessfullRegister.emit();
    }, error => {
      console.log(error);
    });
  }
}
