import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IRegisterServiceProvider } from 'src/app/shared/models/registerServiceProviderModel';
import { MyAccountService } from '../my-account.service';

@Component({
  selector: 'app-my-account-create-admin',
  templateUrl: './my-account-create-admin.component.html',
  styleUrls: ['./my-account-create-admin.component.scss']
})
export class MyAccountCreateAdminComponent implements OnInit {

  AdminForm: FormGroup;
  public serviceProviderInfo: IRegisterServiceProvider;

  @Output() onCancel = new EventEmitter();
  @Output() onSuccessfullRegister = new EventEmitter();

  constructor(private fb: FormBuilder, private myAccountService: MyAccountService) { }

  ngOnInit(): void {
    this.createSPForm();
  }

  createSPForm() {
    this.AdminForm = this.fb.group({
      userName: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')]],
      password: [null, [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
      ]],
      fullAddress: [null, [Validators.required]],
      city: [null, [Validators.required]],
      region: [null, [Validators.required]],
      postalCode: [null, [Validators.required]],
      country: [null, [Validators.required]],
      firstNames: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      contactNumberOne: [null, [Validators.required]],
      contactNumberTwo: [null, [Validators.required]],
    });
  }

  public onSubmit() {
    const oldrgform = (this.AdminForm.value as IRegisterServiceProvider);

    const rgform = (this.AdminForm.value as IRegisterServiceProvider);
    rgform.postalCode = (oldrgform.postalCode as number);
    console.log(rgform);
    this.myAccountService.createAdmin(rgform).subscribe(response => {
      this.onSuccessfullRegister.emit();
    }, error => {
      console.log(error);
    });
  }


}
