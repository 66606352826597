<!-- Sidebar -->
<ul class="navbar-nav sidebar sidebar-dark accordion footer"
  [class]="innerWidth < 1200?toggleSideBarMobileClass : ''" id="accordionSidebar" style="background-color: #2c2c2c!important;">

  <!-- Sidebar - Brand -->
  <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
    <div class="sidebar-brand-icon rotate-n-15">
      <i class="fa fa-laugh-wink"></i>
    </div>
    <img style="border-radius: 2px;" src="carWash.jpg" style="max-width: 50px;border-radius: 25%;"/>
    <div class="sidebar-brand-text mx-3">BookNSplash</div>
  </a>

  <!-- Divider -->
  <hr class="sidebar-divider my-0">

  <!-- Nav Item - Dashboard -->
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/']">
      <i class="fa fa-home"></i>
      <span>Dashboard</span>
    </a>
  </li>

  <li class="nav-item" *ngIf="role ==='Admin'">
    <a class="nav-link" [routerLink]="['/serviceprovidermanagement']">
      <i class="fa fa-cog"></i>
      <span>Service Provider Management</span>
    </a>
  </li>

  <li class="nav-item" *ngIf="role && role !==''">
    <a class="nav-link" [routerLink]="['/myaccount']">
      <i class="fa fa-user"></i>
      <span>My Account</span></a>
  </li>

  <div>

    <!-- Divider -->

    <div style="margin-top: 30vh;">
      <hr class="sidebar-divider">
      <li class="nav-item">
        <a class="nav-link removePaddingBT" style="padding-bottom: 0px;padding-top: 0px;">
          <div class="row">
            <div class="col-md-2">
              <i class="fa fa-user"></i>
            </div>
            <div class="col-md-10">
              <span> Customer Care</span>
            </div>
          </div>
        </a>
        <a class="nav-link removePaddingBT" style="padding-bottom: 0px;padding-top: 0px;">
          <div class="row">
            <div class="col-md-2">
              <i class="fa fa-phone"></i>
            </div>
            <div class="col-md-10">
              <span> 072 195 7981</span>
            </div>
          </div>
        </a>
        <a class="nav-link removePaddingBT" style="padding-bottom: 0px;padding-top: 0px;">
          <div class="row">
            <div class="col-md-2">
              <i class="fa fa-envelope"></i>
            </div>
            <div class="col-md-10">
              <span style="overflow-wrap: break-word;"> info@booknsplash.com</span>
            </div>
          </div>
        </a>
        <a class="nav-link removePaddingBT" style="padding-bottom: 0px;padding-top: 0px;">
          <div class="row">
            <div class="col-md-2">
              <i class="fa fa-facebook-f"></i>
            </div>
            <div class="col-md-10">
              <span><a style="color: white;" target="_blank" href="https://www.facebook.com/booknsplash.wc">
                  Facebook</a></span>
            </div>
          </div>
        </a>
        <a class="nav-link removePaddingBT" style="padding-bottom: 0px;padding-top: 0px;">
          <div class="row">
            <div class="col-md-2">
              <i class="fa fa-instagram"></i>
            </div>
            <div class="col-md-10">
              <span><a style="color: white;" target="_blank"
                  href="https://instagram.com/booknsplash?utm_medium=copy_link"> Instagram</a></span>
            </div>
          </div>
        </a>
        <a class="nav-link" style="padding-bottom: 0px;padding-top: 0px;">
          <div class="row">
            <div class="col-md-2">
              <i class="fa fa-file"></i>
            </div>
            <div class="col-md-10">
              <span><a style="color: white;" target="_blank"
                  href="https://docs.google.com/document/d/1z6Th2LnYLddZxLAuWlaFQkGm9SdhFQB_A5PcH6FZqZo/view"> Terms &
                  Conditions</a></span>
            </div>
          </div>
        </a>
      </li>
    </div>

    <!-- Divider -->
    <hr class="sidebar-divider">
  </div>


  <!-- Divider -->
  <!-- <hr class="sidebar-divider"> -->

  <!-- Nav Item - Pages Collapse Menu -->
  <!-- <li class="nav-item">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
        <i class="fas fa-fw fa-cog"></i>
        <span>Components</span>
      </a>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Custom Components:</h6>
          <a class="collapse-item" href="buttons.html">Buttons</a>
          <a class="collapse-item" href="cards.html">Cards</a>
        </div>
      </div>
    </li> -->

  <!-- Nav Item - Utilities Collapse Menu -->
  <!-- <li class="nav-item">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
        <i class="fas fa-fw fa-wrench"></i>
        <span>Utilities</span>
      </a>
      <div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Custom Utilities:</h6>
          <a class="collapse-item" href="utilities-color.html">Colors</a>
          <a class="collapse-item" href="utilities-border.html">Borders</a>
          <a class="collapse-item" href="utilities-animation.html">Animations</a>
          <a class="collapse-item" href="utilities-other.html">Other</a>
        </div>
      </div>
    </li> -->

  <!-- Divider -->
  <!-- <hr class="sidebar-divider"> -->

  <!-- Heading -->
  <!-- <div class="sidebar-heading">
      Addons
    </div> -->

  <!-- Nav Item - Pages Collapse Menu -->
  <!-- <li class="nav-item">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages" aria-expanded="true" aria-controls="collapsePages">
        <i class="fas fa-fw fa-folder"></i>
        <span>Pages</span>
      </a>
      <div id="collapsePages" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Login Screens:</h6>
          <a class="collapse-item" href="login.html">Login</a>
          <a class="collapse-item" href="register.html">Register</a>
          <a class="collapse-item" href="forgot-password.html">Forgot Password</a>
          <div class="collapse-divider"></div>
          <h6 class="collapse-header">Other Pages:</h6>
          <a class="collapse-item" href="404.html">404 Page</a>
          <a class="collapse-item" href="blank.html">Blank Page</a>
        </div>
      </div>
    </li> -->

  <!-- Nav Item - Charts -->
  <!-- <li class="nav-item">
      <a class="nav-link" href="charts.html">
        <i class="fas fa-fw fa-chart-area"></i>
        <span>Charts</span></a>
    </li> -->

  <!-- Nav Item - Tables -->
  <!-- <li class="nav-item">
      <a class="nav-link" href="tables.html">
        <i class="fas fa-fw fa-table"></i>
        <span>Tables</span></a>
    </li> -->

  <!-- Divider -->
  <!-- <hr class="sidebar-divider d-none d-md-block"> -->

  <!-- Sidebar Toggler (Sidebar) -->
  <!-- <div class="text-center d-none d-md-inline">
      <button class="rounded-circle border-0" id="sidebarToggle"></button>
    </div> -->

</ul>
<!-- End of Sidebar -->