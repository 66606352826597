<div class="row">
    <div class="col-md-12" *ngIf="innerWidth>1200">
        <form [formGroup]="SPForm" (ngSubmit)="onSubmit()" class="user">
            <div class="form-group row">
                <div class="col-sm-6">
                    <app-text-input formControlName="serviceProviderName" [label]="'service Provider Name'">
                    </app-text-input>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6 mb-3 mb-sm-0">
                    <app-text-input formControlName="startTime" [label]="'Start Time'" [type]="'time'"></app-text-input>
                </div>
                <div class="col-sm-6">
                    <app-text-input formControlName="endTime" [label]="'End Time'" [type]="'time'"></app-text-input>
                </div>
            </div>
            <div class="form-group row" style="margin-bottom: 50px;">
                <div class="col-sm-6 mb-3 mb-sm-0">
                    <app-text-input formControlName="capacity" [label]="'Max Capacity'" [type]="'number'">
                    </app-text-input>
                </div>
                <div class="col-sm-6">
                    <mat-slide-toggle formControlName="isClosedOnHolidays">Work on holidays</mat-slide-toggle>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6 mb-3 mb-sm-0">
                    <app-text-input formControlName="fullAddress" [label]="'Full Address'"></app-text-input>
                </div>
                <div class="col-sm-6 mb-3 mb-sm-0">
                    <app-text-input formControlName="city" [label]="'City'"></app-text-input>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                    <app-text-input formControlName="region" [label]="'Region'"></app-text-input>
                </div>
                <div class="col-sm-6 mb-3 mb-sm-0">
                    <app-text-input formControlName="postalCode" [label]="'Postal Code'" [type]="'number'">
                    </app-text-input>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                    <app-text-input formControlName="country" [label]="'Country'"></app-text-input>
                </div>
                <div class="col-sm-6 mb-3 mb-sm-0">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <a class="btn btn-primary btn-user btn-block" style="color:white" (click)="onSubmit()">
                        Update Service Provider
                    </a>
                </div>
                <div class="col-md-6">
                    <a href="javascript:void(0)" (click)="onCancel.emit()" class="btn btn-google btn-user btn-block">
                        Cancel
                    </a>
                </div>
            </div>

        </form>
    </div>
    <div class="col-xl-12" *ngIf="innerWidth<1200">
        <form [formGroup]="SPForm" (ngSubmit)="onSubmit()" class="user">
            <div class="form-group row">
                <div class="col-xl-12 col-12">
                    <app-mobile-text-input formControlName="serviceProviderName" [label]="'service Provider Name'">
                    </app-mobile-text-input>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-xl-6 col-6 mb-3 mb-sm-0">
                    <app-mobile-text-input formControlName="startTime" [label]="'Start Time'" [type]="'time'"></app-mobile-text-input>
                </div>
                <div class="col-xl-6 col-6">
                    <app-mobile-text-input formControlName="endTime" [label]="'End Time'" [type]="'time'"></app-mobile-text-input>
                </div>
            </div>
            <div class="form-group row" >
                <div class="col-xl-6 col-6 mb-3 mb-sm-0">
                    <app-mobile-text-input formControlName="capacity" [label]="'Max Capacity'" [type]="'number'">
                    </app-mobile-text-input>
                </div>
                <div class="col-xl-6 col-6">
                    <mat-slide-toggle formControlName="isClosedOnHolidays">Work on holidays</mat-slide-toggle>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-xl-6 col-6 mb-3 mb-sm-0">
                    <app-mobile-text-input formControlName="fullAddress" [label]="'Full Address'"></app-mobile-text-input>
                </div>
                <div class="col-xl-6 col-6 mb-3 mb-sm-0">
                    <app-mobile-text-input formControlName="city" [label]="'City'"></app-mobile-text-input>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-xl-6 col-6">
                    <app-mobile-text-input formControlName="region" [label]="'Region'"></app-mobile-text-input>
                </div>
                <div class="col-xl-6 col-6 mb-3 mb-sm-0">
                    <app-mobile-text-input formControlName="postalCode" [label]="'Postal Code'" [type]="'number'">
                    </app-mobile-text-input>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-xl-6 col-6">
                    <app-mobile-text-input formControlName="country" [label]="'Country'"></app-mobile-text-input>
                </div>
                <div class="col-sm-6 mb-3 mb-sm-0">
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-6">
                    <a class="btn btn-primary btn-user btn-block" style="color:white" (click)="onSubmit()">
                        Update Service Provider
                    </a>
                </div>
                <div class="col-xl-6 col-6">
                    <a href="javascript:void(0)" (click)="onCancel.emit()" class="btn btn-google btn-user btn-block">
                        Cancel
                    </a>
                </div>
            </div>

        </form>
    </div>
</div>