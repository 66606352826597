import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IBookingReportRequest } from '../shared/models/BookingReportRequestBody';
import { map } from 'rxjs/operators';
import { IChangeServiceProviderStatus } from '../shared/models/changeServiceProviderStatus';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private router: Router) { }

  private getHeaders() {
    const token = localStorage.getItem('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);
    return headers;
  }

  getUserBookings(id: string) {
    const token = localStorage.getItem('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);

    return this.http.get(this.baseUrl + 'booking/getuserbookings' + id, { headers });
  }

  getServiceProviderBookings(id: number) {
    const token = localStorage.getItem('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);
    return this.http.get(this.baseUrl + 'booking/getserviceproviderbookings' + id, { headers });
  }

  public getBookingReport(requestBody: IBookingReportRequest) {
    return this.http
      .post(this.baseUrl + 'report/downloadreport', requestBody, {
        responseType: 'blob',
        observe: 'response'
      })
      .pipe(map((res) => ({
        content: res != null ? res.body : null
      })));
  }

}
