<ng-container *ngIf="isSubcriptionActive">
    <div class="row">
        <div class="col-md-4 col-4 timer"><span id="hours"> {{hoursToDday}} </span></div>
        <div class="col-md-4 col-4 timer"><span id="minutes"> {{minutesToDday}} </span></div>
        <div class="col-md-4 col-4 timer"> <span id="seconds"> {{secondsToDday}} </span></div>
    </div>
    <div class="row">
        <div class="col-md-4 col-4 timer">Hrs</div>
        <div class="col-md-4 col-4 timer">Min</div>
        <div class="col-md-4 col-4 timer">S</div>
    </div>
</ng-container>