import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IConfirmedBookingResponse } from 'src/app/shared/models/confirmedBookingResponse';
import { IGetBooking } from 'src/app/shared/models/getBooking';
import { BookingService } from '../booking.service';

@Component({
  selector: 'app-booking-succeeded',
  templateUrl: './booking-succeeded.component.html',
  styleUrls: ['./booking-succeeded.component.scss']
})
export class BookingSucceededComponent implements OnInit {
  public bookingId = 0;
  public confirmedBooking: IConfirmedBookingResponse;

  public innerWidth: number;

  constructor(private activatedRoute: ActivatedRoute, private bookingService: BookingService, private router: Router) { }

  ngOnInit(): void {
    const bookingId = +localStorage.getItem('bookingId');
    this.confirmAndGetBookingDetails(bookingId);
    localStorage.removeItem('bookingId');

    this.innerWidth = window.innerWidth;
  }

  public confirmAndGetBookingDetails(bookingId: number) {
    console.log(bookingId);
    let getBooking: IGetBooking = new IGetBooking();
    getBooking.bookingId = bookingId;
    console.log(getBooking);
    this.bookingService.confirmBooking(getBooking).subscribe(res => {
      this.confirmedBooking = (res as IConfirmedBookingResponse);
    });
  }

  public onBackToDashboardClicked() {
    this.router.navigateByUrl('/');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    console.log(this.innerWidth);
  }

}
