<meta name="viewport" content="width=device-width, initial-scale=1">
<div id="wrapper" >
  <app-side-bar></app-side-bar>
  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column;">
    <!-- Main Content -->
    <div id="content">
      <app-top-bar></app-top-bar>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>