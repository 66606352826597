import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IRegisterServiceProvider } from 'src/app/shared/models/registerServiceProviderModel';
import { ServiceProviderService } from '../service-provider.service';

@Component({
  selector: 'app-service-provider-create',
  templateUrl: './service-provider-create.component.html',
  styleUrls: ['./service-provider-create.component.scss']
})
export class ServiceProviderCreateComponent implements OnInit {
  SPForm: FormGroup;
  public serviceProviderInfo: IRegisterServiceProvider;

  @Input() serviceProviderId: number = 0;

  @Input() innerWidth: number;

  @Output() onCancel = new EventEmitter();
  @Output() onSuccessfullRegister = new EventEmitter();

  constructor(private fb: FormBuilder, private serviceProviderService: ServiceProviderService) { }

  ngOnInit(): void {
    console.log(this.serviceProviderId);
    if (this.serviceProviderId === 0) {
      this.createSPForm();
    } else {
      this.getServiceProviderUserInfo(this.serviceProviderId);
    }

  }

  getServiceProviderUserInfo(id: number) {
    this.serviceProviderService.getServiceProvider(id).subscribe(response => {
      this.serviceProviderInfo = (response as IRegisterServiceProvider);
      this.fillSPForm();
    }, error => {
      console.log(error);
    });
  }

  createSPForm() {
    this.SPForm = this.fb.group({
      userName: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')]],
      password: [null, [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
      ]],
      fullAddress: [null, [Validators.required]],
      city: [null, [Validators.required]],
      region: [null, [Validators.required]],
      postalCode: [null, [Validators.required]],
      country: [null, [Validators.required]],
      firstNames: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      contactNumberOne: [null, [Validators.required]],
      contactNumberTwo: [null, [Validators.required]],
      serviceProviderName: [null, [Validators.required]],
      startTime: [null, [Validators.required]],
      endTime: [null, [Validators.required]],
      capacity: [null, [Validators.required]],
      isClosedOnHolidays: [false, [Validators.required]],
    });
  }

  fillSPForm() {
    this.SPForm = this.fb.group({
      userName: [this.serviceProviderInfo.username, [Validators.required]],
      email: [this.serviceProviderInfo.email, [Validators.required, Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')]],
      password: [this.serviceProviderInfo.password, [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
      ]],
      fullAddress: [this.serviceProviderInfo.fullAddress, [Validators.required]],
      city: [this.serviceProviderInfo.city, [Validators.required]],
      region: [this.serviceProviderInfo.region, [Validators.required]],
      postalCode: [this.serviceProviderInfo.postalCode, [Validators.required]],
      country: [this.serviceProviderInfo.country, [Validators.required]],
      firstNames: [this.serviceProviderInfo.firstNames, [Validators.required]],
      lastName: [this.serviceProviderInfo.lastName, [Validators.required]],
      contactNumberOne: [this.serviceProviderInfo.contactNumberOne, [Validators.required]],
      contactNumberTwo: [this.serviceProviderInfo.contactNumberTwo, [Validators.required]],
      serviceProviderName: [this.serviceProviderInfo.serviceProviderName, [Validators.required]],
    });
  }

  public onSubmit() {
    const oldrgform = (this.SPForm.value as IRegisterServiceProvider);

    const rgform = (this.SPForm.value as IRegisterServiceProvider);
    rgform.postalCode = (oldrgform.postalCode as number);
    console.log(rgform);
    this.serviceProviderService.register(rgform).subscribe(response => {
      this.onSuccessfullRegister.emit();
    }, error => {
      console.log(error);
    });
  }

}
