import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from 'src/app/account/account.service';
import { IUpdatePassword, IUpdatePasswordRequest } from 'src/app/shared/models/updatePassword';
import { UserService } from 'src/app/shared/services/UserService';
import { MyAccountService } from '../my-account.service';

@Component({
  selector: 'app-my-account-update-password',
  templateUrl: './my-account-update-password.component.html',
  styleUrls: ['./my-account-update-password.component.scss']
})
export class MyAccountUpdatePasswordComponent implements OnInit {
  SPForm: FormGroup;
  public serviceProviderInfo: IUpdatePassword;

  @Input() userId: string = "";

  @Input() isEdit: boolean = false;

  @Output() onCancel = new EventEmitter();
  @Output() onSuccessfullUpdate = new EventEmitter();

  constructor(private fb: FormBuilder, private myAccountService: MyAccountService, private userService: UserService, private accountService: AccountService) { }

  ngOnInit(): void {
    this.userId = this.userService.getUserId();
    this.createSPForm();
  }

  createSPForm() {
    this.SPForm = this.fb.group({
      password: [null, [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
      ]],
      confirmPassword: [null, [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
      ]]
    });
  }

  public onSubmit() {
    const oldrgform = (this.SPForm.value as IUpdatePassword);

    const rgform = (this.SPForm.value as IUpdatePassword);

    let updatePasswordRequest: IUpdatePasswordRequest = {
      password: rgform.password,
      email: null,
      userId: this.userId
    };
    console.log(rgform);
    this.myAccountService.updatePassword(updatePasswordRequest).subscribe(response => {
      this.onSuccessfullUpdate.emit();
      this.accountService.logout();
    }, error => {
      console.log(error);
    });
  }

}
