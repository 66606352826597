import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { BookingService } from 'src/app/booking/booking.service';

@Component({
  selector: 'app-count-down-timer',
  templateUrl: './count-down-timer.component.html',
  styleUrls: ['./count-down-timer.component.scss']
})
export class CountDownTimerComponent implements OnInit, OnDestroy {
  public dDay = new Date();

  private subscription: Subscription;

  public dateNow = new Date();
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  public isSubcriptionActive = false;


  constructor(private bookingService: BookingService) {
    this.bookingService.OnNextBookingLoaded.subscribe((x) => {
      this.isSubcriptionActive = true;
      console.log(x);
      this.dDay = new Date(x);
      this.subscription = interval(1000)
        .subscribe(x => { this.getTimeDifference(); });
    });
  }


  private getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
    if (this.timeDifference <= 598) {
      this.bookingService.OnNextBookingCompleted.emit();
    }
  }

  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    if (this.isSubcriptionActive) {
      this.subscription.unsubscribe();
      this.isSubcriptionActive = false;
    }
  }
}
