<!-- Begin Page Content -->
<div class="container-fluid" style="overflow: auto; max-height: 85vh;">

    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 style="max-width: fit-content;" class="h3 mb-0 text-gray-800" *ngIf="!this.isAddServiceTypeFormVisible">
            Service Provider Management
            <ng-container *ngIf="selectedServiceProvderName">
                : {{selectedServiceProvderName}}
            </ng-container>
        </h1>
    </div>

    <div class="row" *ngIf="this.isServiceProviderTableVisible">
        <div class="col-xl-12 col-12">
            <div class="table-responsive" [ngStyle]="{'max-width': innerWidth>1200?'':'fit-content'}">
                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th>Service Provider</th>
                            <th style="width: 18rem; text-align: center;">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of this.listOfSPS">
                            <td>{{item.name}}</td>
                            <td>
                                <button class="btn btn-link" title="Edit Service Provider" style="margin-left: 10px"
                                    (click)="onServiceProviderEditClicked(item.id,item.name)">
                                    <i class="fa fa-pencil"></i>
                                </button>
                                <button class="btn btn-link" title="Manage User Details" style="margin-left: 10px"
                                    (click)="onServiceProviderProfileManageClicked(item.id,item.name)">
                                    <i class="fa fa-user"></i>
                                </button>
                                <button class="btn btn-link" title="Manage Services" style="margin-left: 10px"
                                    (click)="onManageServiceProviderServicesClicked(item.id,item.name)">
                                    <i class="fa fa-plus-square"></i>
                                </button>
                                <button class="btn btn-link" title="Update Password" style="margin-left: 10px"
                                    (click)="onServiceProviderPasswordManageClicked(item.id,item.name)">
                                    <i class="fa fa-key"></i>
                                </button>
                                <button class="btn btn-link" title="Activate Service Provider" style="margin-left: 10px"
                                    *ngIf="!item.active" (click)="activateServiceProvider(item.id)">
                                    <i class="fa fa-link"></i>
                                </button>
                                <button class="btn btn-link" title="Deactivate Service Provider"
                                    style="margin-left: 10px" *ngIf="item.active"
                                    (click)="deactivateServiceProvider(item.id)">
                                    <i class="fa fa-unlink"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="innerWidth>1200">
        <div class="col-xl-12 col-12">
            <div class="row">
                <div class="col-xl-10 col-10 text-right" *ngIf="this.isServiceProviderTableVisible">
                    <button class="btn btn-primary" (click)="showAddServiceProviderForm()"><i class="fa fa-plus"></i>
                        Add a
                        Service
                        Provider</button>

                </div>
                <div class="col-xl-2 col-2 text-left" *ngIf="this.isServiceProviderTableVisible">
                    <button class="btn btn-primary" (click)="showAddServiceTypeForm()"><i class="fa fa-plus"></i> Add
                        new
                        Service
                        Type</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="max-width: fit-content;" *ngIf="innerWidth<1200">
        <div class="col-xl-12 col-12" >
            <div class="row" style="margin-bottom: 10px;">
                <div class="col-xl-12 col-12 text-align" style="max-width:max-content;"
                    *ngIf="this.isServiceProviderTableVisible">
                    <button class="btn btn-primary" (click)="showAddServiceProviderForm()"><i class="fa fa-plus"></i>
                        Add a
                        Service
                        Provider</button>

                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-12 " *ngIf="this.isServiceProviderTableVisible">
                    <button class="btn btn-primary" (click)="showAddServiceTypeForm()"><i class="fa fa-plus"></i>
                        Add
                        new
                        Service
                        Type</button>
                </div>
            </div>
        </div>
    </div>

    <app-service-provider-create (onCancel)="closeAddSPForm()" (onSuccessfullRegister)="onSuccessfullRegister()"
        *ngIf="this.isAddServiceProviderFormVisible" [innerWidth]="innerWidth">
    </app-service-provider-create>

    <app-service-provider-edit (onCancel)="closeAddSPForm()" (onSuccessfullRegister)="onSuccessfullRegister()"
        [serviceProviderId]="this.selectedServiceProviderId" *ngIf="this.isEditServiceProviderFormVisible" [innerWidth]="innerWidth">
    </app-service-provider-edit>
    <app-service-provider-manage-user (onCancel)="closeAddSPForm()" (onSuccessfullRegister)="onSuccessfullRegister()"
        [serviceProviderId]="this.selectedServiceProviderId" *ngIf="this.isManageUserServiceProviderFormVisible" [innerWidth]="innerWidth">
    </app-service-provider-manage-user>
    <app-service-provider-manage-user-password (onCancel)="closeAddSPForm()"
        (onPasswordUpdate)="onSuccessfullPasswordUpdate()" [serviceProviderId]="this.selectedServiceProviderId"
        *ngIf="this.isManageUserPasswordServiceProviderFormVisible" [innerWidth]="innerWidth">
    </app-service-provider-manage-user-password>
    <app-service-provider-manage-services (onCancel)="closeAddSPForm()"
        (onSuccessfullRegister)="onSuccessfullRegister()" [serviceProviderId]="this.selectedServiceProviderId"
        *ngIf="this.isManageServicesServiceProviderFormVisible" [innerWidth]="innerWidth">
    </app-service-provider-manage-services>
    <app-service-type-create (onCancel)="closeAddSPForm()" (onSuccessfullRegister)="onSuccessfullRegister()"
        *ngIf="this.isAddServiceTypeFormVisible" [innerWidth]="innerWidth">
    </app-service-type-create>

</div>
<!-- /.container-fluid -->