<div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">My Account
    </h1>
</div>
<div class="row" *ngIf="innerWidth>1200">
    <div class="col-xl-12 col-12">
        <form [formGroup]="userInfoForm" (ngSubmit)="onSubmit()" class="form-group">
            <div class="form-group row">
                <div class="col-xl-6 col-6 mb-3 mb-sm-0">
                    <app-text-input formControlName="firstNames" [label]="'First Name'" [disabled]="!isEdit" >
                    </app-text-input>
                </div>
                <div class="col-xl-6 col-6">
                    <app-text-input formControlName="lastName" [label]="'Last Name'" [disabled]="!isEdit">
                    </app-text-input>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-xl-6 col-6 mb-3 mb-sm-0">
                    <app-text-input formControlName="email" [label]="'Email Address'" [disabled]="!isEdit">
                    </app-text-input>
                </div>
                <div class="col-xl-6 col-6">
                    <app-text-input formControlName="contactNumberOne" [label]="'Contact Number One'"
                        [disabled]="!isEdit">
                    </app-text-input>
                </div>
            </div>
            <div class="form-group row" *ngIf="this.role === 'Customer'">
                <div class="col-xl-12 col-12 mb-3">
                    <app-text-input formControlName="registrationNumber" [label]="'Registration number'"
                        [disabled]="!isEdit">
                    </app-text-input>
                </div>
            </div>
            <div class="form-group row" *ngIf="this.isEdit">
                <div class="col-xl-6 col-6">
                    <a class="btn btn-primary btn-user btn-block" style="color:white" (click)="onSubmit()">
                        Update
                    </a>
                </div>
                <div class="col-xl-6 col-6">
                    <a href="javascript:void(0)" (click)="onCancelClicked()" class="btn btn-google btn-user btn-block">
                        Cancel
                    </a>
                </div>
            </div>

        </form>
    </div>
</div>
<div class="row" *ngIf="innerWidth<1200">
    <div class="col-xl-12 col-12">
        <form [formGroup]="userInfoForm" (ngSubmit)="onSubmit()" class="form-group">
            <div class="form-group row">
                <div class="col-xl-6 col-6 mb-3 mb-sm-0">
                    <app-mobile-text-input formControlName="firstNames" [label]="'First Name'" [disabled]="!isEdit" >
                    </app-mobile-text-input>
                </div>
                <div class="col-xl-6 col-6">
                    <app-mobile-text-input formControlName="lastName" [label]="'Last Name'" [disabled]="!isEdit">
                    </app-mobile-text-input>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-xl-6 col-6 mb-3 mb-sm-0">
                    <app-mobile-text-input formControlName="email" [label]="'Email Address'" [disabled]="!isEdit">
                    </app-mobile-text-input>
                </div>
                <div class="col-xl-6 col-6">
                    <app-mobile-text-input formControlName="contactNumberOne" [label]="'Contact Number One'"
                        [disabled]="!isEdit">
                    </app-mobile-text-input>
                </div>
            </div>
            <div class="form-group row" *ngIf="this.role === 'Customer'">
                <div class="col-xl-12 col-12 mb-3">
                    <app-mobile-text-input formControlName="registrationNumber" [label]="'Registration number'"
                        [disabled]="!isEdit">
                    </app-mobile-text-input>
                </div>
            </div>
            <div class="form-group row" *ngIf="this.isEdit">
                <div class="col-xl-6 col-6">
                    <a class="btn btn-primary btn-user btn-block" style="color:white" (click)="onSubmit()">
                        Update
                    </a>
                </div>
                <div class="col-xl-6 col-6">
                    <a href="javascript:void(0)" (click)="onCancelClicked()" class="btn btn-google btn-user btn-block">
                        Cancel
                    </a>
                </div>
            </div>

        </form>
    </div>
</div>