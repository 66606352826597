<div class="container-fluid" style="overflow: auto; max-height: 85vh;" *ngIf="this.canceledBooking">
    <div class="row">
        <!-- Area Chart -->
        <div class="col-xl-12 col-12">
          <div class="card shadow mb-4">
            <!-- Card Header - Dropdown -->
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h2 class="m-0 font-weight-bold text-primary">{{this.canceledBooking.serviceProviderDetails.serviceProviderName}}</h2>
            </div>
            <!-- Card Body -->
            <div class="card-body">
              <ul class="list-unstyled">
                <li>
                  <div class="row">
                    <div class="col-xl-2 col-2" style="text-align: center;"><i class="fa fa-map-marker fa-2x"></i></div>
                    <div class="col-xl-10 col-10">
                      <span> {{this.canceledBooking.serviceProviderDetails.fullAddress}}</span>
                      <span> {{this.canceledBooking.serviceProviderDetails.region}}</span>
                      <span> {{this.canceledBooking.serviceProviderDetails.city}}</span>
                      <span> {{this.canceledBooking.serviceProviderDetails.postalCode}}</span>
                    </div>
                  </div>
                </li>
    
                <li>
                  <div class="row" style="margin-top: 10px;">
                    <div class="col-xl-2 col-2" style="text-align: center;"><i class="fa fa-phone mt-4 fa-2x"
                        style="margin-top: 0px !important;"></i></div>
                    <div class="col-xl-10 col-10">
                      <span> {{this.canceledBooking.serviceProviderDetails.contactNumberOne}}</span>
                      <span> {{this.canceledBooking.serviceProviderDetails.contactNumberTwo}}</span>
                    </div>
                  </div>
                </li>
    
                <li>
                  <div class="row" style="margin-top: 10px;">
                    <div class="col-xl-2 col-2" style="text-align: center;">
                      <i class="fa fa-envelope mt-4 fa-2x" style="margin-top: 0px !important;"></i>
                    </div>
                    <div class="col-xl-10 col-10">
                      <span> {{this.canceledBooking.serviceProviderDetails.email}}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">

        <!-- Area Chart -->
        <div class="col-xl-12 col-12">
          <div class="card shadow mb-4">
            <!-- Card Header - Dropdown -->
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h4 style="max-width: fit-content;" class="m-0 font-weight-bold text-primary">Your booking payment was unsuccessful</h4>
            </div>
            <!-- Card Body -->
            <div class="card-body">
              <h6 style="max-width: fit-content;"  class="m-0 font-weight-bold text-primary mb-4">Please try and book again.</h6>
              <div class="form-group row float-right">
                <div class="col-xl-12 col-12">
                  <button class="btn btn-info" (click)="onBackToDashboardClicked()"><i class="fas fa-columns"></i> Back To Dashboard</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>