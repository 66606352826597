import { EventEmitter, Injectable } from "@angular/core";
import { IUser } from "../models/user";

export class UserService {
    public loggedInUser: IUser = new IUser();
    public OnUserLoggedIn = new EventEmitter();
    public OnUserLoggedOut = new EventEmitter();

    public setLoggedInUser(user: IUser) {
        this.loggedInUser = user;
        localStorage.setItem('token', user.token);
        localStorage.setItem('userId', user.userId);
        localStorage.setItem('firstName', user.firstName);
        localStorage.setItem('role', user.role);
        if (user.role ==='ServiceProvider') {
            localStorage.setItem('serviceProviderId', user.serviceProviderId.toString());  
        }
        this.OnUserLoggedIn.emit();
    }

    public getToken() {
        let token = localStorage.getItem('token');
        return token;
    }

    public getUserId() {
        let userId = localStorage.getItem('userId');
        return userId;
    }

    public getFirstName() {
        let displayName = localStorage.getItem('firstName');
        return displayName;
    }

    public getRole() {
        let role = localStorage.getItem('role');
        return role;
    }

    public getServiceProviderId() {
        let serviceProviderId = localStorage.getItem('serviceProviderId');
        return serviceProviderId;
    }

    public clearUserInformationForLogout(){
        if (this.getRole() ==='ServiceProvider') {
            localStorage.removeItem('serviceProviderId');  
        }
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('firstName');
        localStorage.removeItem('role');
        this.OnUserLoggedOut.emit();
    }
}