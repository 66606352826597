<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Create an Account</h4>
  <div style="padding: 2px;">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="modal-body">
  <form [formGroup]="registerForm" class="user">
    <div class="form-group row">
      <div class="col-sm-6 mb-3 mb-sm-0">
        <app-text-input formControlName="firstNames" [label]="'First Name'"></app-text-input>
      </div>
      <div class="col-sm-6">
        <app-text-input formControlName="lastName" [label]="'Last Name'"></app-text-input>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-6">
        <app-text-input formControlName="email" [label]="'Email Address'"></app-text-input>
      </div>
      <div class="col-sm-6">
        <app-text-input formControlName="contactNumberOne" [label]="'Contact Number'">
        </app-text-input>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <div class="form-group row">
            <div class="col-10">
                <app-text-input formControlName="password" [(type)]="type" [label]="'Password'"></app-text-input>
            </div>
            <div class="col-2">
                <div style="padding: 10px;"><i class="fa fa-eye-slash" (click)="onChangeType(type ==='password'?'text':'password')"></i></div>
            </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12 mb-3 mb-sm-0">
        <i class="fa fa-info-circle"></i>
        <span style="font-size:x-small;"> Your Password must be 8 characters long and contain one upper case letter, one
          lowercase letter, one special character and one number</span>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <app-text-input formControlName="registrationNumber" [label]="'Vehicle Registration Number'">
        </app-text-input>
      </div>
    </div>
    <ul class="text-danger list-unstyled" *ngIf="errors">
      <li>
        {{this.errors}}
      </li>
    </ul>
    <div class="row">
      <div class="col-lg-12 text-center">
        <button [disabled]="registerForm.invalid || this.termsAndConditionsChecked === false" style="color: white;"
          class="btn btn-primary" (click)="onSubmit()">
          <i class="fa fa-pencil"></i>
          Register Account
        </button>
      </div>
    </div>
  </form>
  <div class="form-group row" style="margin-top: 10px;">
    <div class="col-sm-12">
      <b>To sign up please agree to our Terms and Conditions</b>
    </div>
    <div class="col-sm-12">
      <input type="checkbox" [(ngModel)]="this.termsAndConditionsChecked">
      <span> Agree to <a target="_blank"
          href="https://docs.google.com/document/d/1z6Th2LnYLddZxLAuWlaFQkGm9SdhFQB_A5PcH6FZqZo/view"> T&C's </a></span>
    </div>
  </div>
  <hr>
  <div class="text-center">
    <a class="small" href="javascript:void(0)" (click)="showForgotPassword()">Forgot Password?</a>
  </div>
  <div class="text-center">
    <a class="small" href="javascript:void(0)" (click)="showLoginForm()">Already have an account? Login!</a>
  </div>
</div>
<!-- <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div> -->
