import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { IUser } from 'src/app/shared/models/user';
import { UserService } from 'src/app/shared/services/UserService';
import { AccountService } from '../account.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  public errors : string;
  public type = 'password';

  @Output() CloseModal = new EventEmitter();
  @Output() ShowRegisterForm = new EventEmitter();
  @Output() ShowForgotPassword = new EventEmitter();

  constructor(private accountService: AccountService, private userService:UserService, private router: Router) { }

  ngOnInit(): void {
    this.createLoginForm();
  }

  public closeLoginModal() {
    this.CloseModal.emit();
  }

  public showRegisterForm(){
    this.ShowRegisterForm.emit();
  }

  public showForgotPassword(){
    this.ShowForgotPassword.emit();
  }

  createLoginForm() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators
        .pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')]),
      password: new FormControl('', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')])
    });
  }

  onSubmit() {
    this.accountService.login(this.loginForm.value).subscribe(user => {
      let loggedInUserInfo = (user as IUser);
      if (loggedInUserInfo) {
        this.userService.setLoggedInUser(loggedInUserInfo);
      }
      console.log(user as IUser);
      this.CloseModal.emit();
      this.router.navigateByUrl('/');
    },
      error => {
        console.log(error);
        this.errors = error.error.errors.Login;
      }
    );
  }

  onChangeType(event) {
    this.type = event;
  }
}