import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IUser } from '../shared/models/user';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IAddServiceProviderServiceType } from '../shared/models/addServiceProviderServiceType';
import { IUpdateServiceProviderServiceType } from '../shared/models/updateServiceProviderServiceType';
import { IServiceType } from '../shared/models/serviceType';
import { IChangeServiceProviderStatus } from '../shared/models/changeServiceProviderStatus';

@Injectable({
  providedIn: 'root'
})
export class ServiceProviderService {
  baseUrl = environment.apiUrl;
  private currentUserSource = new BehaviorSubject<IUser>(null);
  currentUser$ = this.currentUserSource.asObservable();
  constructor(private http: HttpClient, private router: Router) { }

  register(values: any) {
    const token = localStorage.getItem('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.baseUrl + 'usermanager/registerserviceprovider', values);
  }

  update(values: any) {
    const token = localStorage.getItem('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);

    return this.http.post(this.baseUrl + 'usermanager/updateserviceprovider', values,{headers});
  }

  updateUserInformation(values: any) {
    const token = localStorage.getItem('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);

    return this.http.post(this.baseUrl + 'usermanager/updateserviceprovideruserinformation', values,{headers});
  }

  getServiceProviders() {
    return this.http.get(this.baseUrl + 'usermanager/getserviceproviders');
  }

  getServiceProvider(id: number) {
    return this.http.get(this.baseUrl + 'usermanager/getserviceprovider' + id);
  }

  checkEmailExists(email: string) {
    const token = localStorage.getItem('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);

    return this.http.get(this.baseUrl + '/account/emailexits?email=' + email,{headers});
  }

  addServiceType(serviceType: IServiceType) {
    const token = localStorage.getItem('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);

    return this.http.post(this.baseUrl + 'servicetype/addservicetype', serviceType, {headers});
  }

  getServiceTypes() {
    return this.http.get(this.baseUrl + 'servicetype/getservicetypes');
  }

  getServiceProviderServiceTypes(serviceProviderId: number) {
    return this.http.get(this.baseUrl + 'serviceproviderservicetype/getserviceproviderservicetypes' + serviceProviderId);
  }

  addServiceProviderServiceType(addServiceProviderServiceType: IAddServiceProviderServiceType) {
    const token = localStorage.getItem('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);

    return this.http.post(this.baseUrl + 'serviceproviderservicetype/addserviceproviderservicetype', addServiceProviderServiceType,{headers});
  }

  deleteServiceProviderServiceType(serviceProviderServiceTypeId:number) {
    const token = localStorage.getItem('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);

    return this.http.post(this.baseUrl + 'serviceproviderservicetype/deleteserviceproviderservicetype', serviceProviderServiceTypeId,{headers});
  }

  updateServiceProviderServiceType(addServiceProviderServiceType: IUpdateServiceProviderServiceType) {
    const token = localStorage.getItem('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);

    return this.http.post(this.baseUrl + 'serviceproviderservicetype/updateserviceproviderservicetype', addServiceProviderServiceType,{headers});
  }

  updatePassword(values: any) {
    return this.http.post(this.baseUrl + 'usermanager/updatepassword', values);
  }

  deactivateServiceProvider(values: any) {
    return this.http.post(this.baseUrl + 'usermanager/deactivateserviceprovider', values);
  }

  activateServiceProvider(values: any) {
    return this.http.post(this.baseUrl + 'usermanager/activateserviceprovider', values);
  }

  changeServiceProviderStatus(changeServiceProviderStatus: IChangeServiceProviderStatus) {
    const token = localStorage.getItem('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);

    return this.http.post<boolean>(this.baseUrl + 'serviceprovidermanagement/changeserviceproviderstatus', changeServiceProviderStatus, {headers});
  }

  checkServiceProviderActiveBookings(id: number) {
    const token = localStorage.getItem('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);
    return this.http.get<boolean>(this.baseUrl + 'booking/checkserviceprovideractivebookings' + id, { headers });
  }
}

