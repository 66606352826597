import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IRegisterServiceProvider } from 'src/app/shared/models/registerServiceProviderModel';
import { IUpdatePassword, IUpdatePasswordRequest } from 'src/app/shared/models/updatePassword';
import { UserService } from 'src/app/shared/services/UserService';
import { ServiceProviderService } from '../service-provider.service';

@Component({
  selector: 'app-service-provider-manage-user-password',
  templateUrl: './service-provider-manage-user-password.component.html',
  styleUrls: ['./service-provider-manage-user-password.component.scss']
})
export class ServiceProviderManageUserPasswordComponent implements OnInit {
  SPForm: FormGroup;
  public serviceProviderInfo: IRegisterServiceProvider;
  @Input() userId: string = "";

  @Input() serviceProviderId: number = 0;

  @Input() innerWidth: number;

  @Output() onCancel = new EventEmitter();
  @Output() onPasswordUpdate = new EventEmitter();

  constructor(private fb: FormBuilder, private serviceProviderService: ServiceProviderService, private userService: UserService) { }

  ngOnInit(): void {
    this.userId = this.userService.getUserId();
    console.log(this.serviceProviderId);
    this.createSPForm();
    this.getServiceProviderUserInfo(this.serviceProviderId);
  }

  getServiceProviderUserInfo(id: number) {
    this.serviceProviderService.getServiceProvider(id).subscribe(response => {
      this.serviceProviderInfo = (response as IRegisterServiceProvider);
    }, error => {
      console.log(error);
    });
  }

  createSPForm() {
    this.SPForm = this.fb.group({
      password: [null, [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
      ]],
      confirmPassword: [null, [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
      ]]
    });
  }

  public onSubmit() {
    const oldrgform = (this.SPForm.value as IUpdatePassword);

    const rgform = (this.SPForm.value as IUpdatePassword);

    let updatePasswordRequest: IUpdatePasswordRequest = {
      password: rgform.password,
      email: this.serviceProviderInfo.email,
      userId: null
    };

    this.serviceProviderService.updatePassword(updatePasswordRequest).subscribe(response => {
      this.onPasswordUpdate.emit();
    }, error => {
      console.log(error);
    });
  }

}
