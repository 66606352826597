<!-- Topbar -->
<nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">


    <!-- Sidebar Toggle (Topbar) -->
    <button id="sidebarToggleTop" (click)="toggleSidbar()" class="btn btn-link d-md-none rounded-circle mr-3">
      <i class="fa fa-bars"></i>
    </button>
    <ng-container *ngIf="this.isUserLoggedIn">
      <span class="mr-2 d-lg-inline text-gray-600 small">Welcome back <b>{{firstName}}</b></span>
    </ng-container>
    <!-- Topbar Navbar -->
    <ul class="navbar-nav ml-auto">
      <!-- Nav Item - User Information -->
      <li class="nav-item dropdown no-arrow ml-auto">
        <button class="btn btn-primary" style="margin-top: 10px;" *ngIf="!isUserLoggedIn"
          (click)="open(content)">Login</button>
        <button class="btn btn-primary" style="margin-top: 10px;" *ngIf="isUserLoggedIn"
          (click)="logout()">Logout</button>
      </li>

    </ul>

</nav>

<ng-template #content let-modal>
  <app-login *ngIf="this.isLoginFormActive && !this.isForgotPasswordActive" (CloseModal)="modal.dismiss('Cross click')"
    (ShowRegisterForm)="hideLoginForm()" (ShowForgotPassword)="showForgotPassword()"></app-login>
  <app-register *ngIf="!this.isLoginFormActive && !this.isForgotPasswordActive"
    (CloseModal)="modal.dismiss('Cross click')" (ShowLoginForm)="showLoginForm()"
    (ShowForgotPassword)="showForgotPassword()"></app-register>
  <app-forgot-password *ngIf="this.isForgotPasswordActive" (CloseModal)="modal.dismiss('Cross click')"
    (ShowLoginForm)="showLoginForm()"></app-forgot-password>
  <!-- <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Date of birth</label>
        <div class="input-group">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
  </div> -->
</ng-template>
<!-- End of Topbar -->