import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/services/UserService';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  public isEditPassword = false;
  public isEditUserDetails = false;
  public isCreateAdmin = false;
  public role = "";

  constructor(private userService: UserService) {
    this.userService.OnUserLoggedIn.subscribe(() => {
      this.role = this.userService.getRole();
    });
    this.userService.OnUserLoggedOut.subscribe(() => {
      this.role = "";
    });
  }

  ngOnInit(): void {
    this.role = this.userService.getRole();
  }

  public onEditUserDetailsClick() {
    this.isEditUserDetails = true;
  }

  public onUpdatePasswordClick() {
    this.isEditPassword = true;
  }

  public onCreateAdminClicked() {
    this.isCreateAdmin = true;
  }

  public onCancelEditUserDetails() {
    this.isEditUserDetails = false;
  }

  public onCancelUpdatePassword() {
    this.isEditPassword = false;
  }

  public onCreateAdminEventEmitted() {
    this.isCreateAdmin = false;
  }

}
