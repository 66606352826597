<div class="form-label-group" >
    <input style="max-width: fit-content;"
        [ngClass]="(controlDir && controlDir.control && controlDir.control.touched) ? !controlDir.control.valid ? 'is-invalid' : 'is-valid' : null"
        [type]="type" [disabled]="disabled" (input)="onChange($event.target.value)" (blur)="onTouched()" id="{{label}}"
        #input class="form-control" placeholder="{{label}}"
        (keydown.space)=" label === 'Username' ? $event.preventDefault() :$event ">

    <div *ngIf="controlDir && controlDir.control && controlDir.control.status === 'PENDING'"
        class="fa fa-spinner fa-spin loader"></div>
    <label for="{{label}}">{{label}}</label>
    <div class="invalid-feedback"
        *ngIf="(controlDir && controlDir.control && !controlDir.control.valid && controlDir.control.touched)">
        <span *ngIf="controlDir.control.errors?.required">{{label}} is required</span>
        <span *ngIf="controlDir.control.errors?.pattern && label === 'Email'">Invalid email address</span>
        <span *ngIf="controlDir.control.errors?.pattern && label === 'Password'">Invalid password</span>
    </div>

    <div class="invalid-feedback d-block"
        *ngIf="(controlDir && controlDir.control && !controlDir.control.valid && controlDir.control.dirty)">
        <span *ngIf="controlDir.control.errors?.emailExists">Email address is in use</span>
    </div>
</div>