<div class="row">
    <div class="col-xl-12 col-12">
        <form [formGroup]="SPForm" (ngSubmit)="onSubmit()" class="user">
            <div class="form-group">
                <app-text-input formControlName="password" [label]="'Password'"></app-text-input>

            </div>
            <div class="form-group">
                <app-text-input formControlName="confirmPassword" [label]="'Confirm Password'"></app-text-input>

            </div>
            <div class="row">
                <div class="col-xl-6 col-6">
                    <a class="btn btn-primary btn-user btn-block" style="color:white" (click)="onSubmit()">
                        Update Password
                    </a>
                </div>
                <div class="col-xl-6 col-6">
                    <a href="javascript:void(0)" (click)="onCancel.emit()" class="btn btn-google btn-user btn-block">
                        Cancel
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>