import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeModule } from './home/home.module';
import { CoreModule } from './core/core.module';
import { LoginComponent } from './account/login/login.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { AccountbaseComponent } from './account/accountbase/accountbase.component';
import { RegisterComponent } from './account/register/register.component';
import { ServiceProviderManagementComponent } from './service-provider-management/service-provider-management.component';
import { ServiceProviderModule } from './service-provider-management/service-provider.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCommonModule } from '@angular/material/core';
import { ServiceProviderDetailsComponent } from './service-provider-details/service-provider-details.component';
import { PaymentsComponent } from './payments/payments.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { MyAccountUserInfoComponent } from './my-account/my-account-user-info/my-account-user-info.component';
import { MyAccountUpdatePasswordComponent } from './my-account/my-account-update-password/my-account-update-password.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BookingComponent } from './booking/booking.component';
import { BookingModule } from './booking/booking.module';
import { MyAccountCreateAdminComponent } from './my-account/my-account-create-admin/my-account-create-admin.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    ServiceProviderDetailsComponent,
    PaymentsComponent,
    MyAccountComponent,
    MyAccountUserInfoComponent,
    MyAccountUpdatePasswordComponent,
    BookingComponent,
    MyAccountCreateAdminComponent
  ],
  imports: [
    NgxSmartModalModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HomeModule,
    CoreModule,
    NgxSmartModalModule,
    HttpClientModule,
    SharedModule,
    ServiceProviderModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatCommonModule,
    MatCommonModule,
    NgbModule,
    BookingModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
