import { Time } from "@angular/common";

export class IServiceProviderDetails {
    username: string;
    email: string;
    password: string;
    fullAddress: string;
    city: string;
    region: string;
    postalCode: number;
    country: string;
    firstNames: string;
    lastName: string;
    contactNumberOne: string;
    contactNumberTwo: string;
    serviceProviderName: string;
    startTime: Time;
    endTime: Time;
    Capacity: number;
    isClosedOnHolidays: boolean;
    isOnline :boolean;
}
