import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICreateBooking } from '../shared/models/createBooking';
import { IUser } from '../shared/models/user';

@Injectable({
  providedIn: 'root'
})
export class ServiceProviderDetailsService {
  baseUrl = environment.apiUrl;
  private currentUserSource = new BehaviorSubject<IUser>(null);
  currentUser$ = this.currentUserSource.asObservable();
  constructor(private http: HttpClient, private router: Router) { }

  getServiceProvider(id: number) {
    return this.http.get(this.baseUrl + 'usermanager/getserviceprovider' + id);
  }

  getServiceProviderServiceTypes(serviceProviderId: number) {
    return this.http.get(this.baseUrl + 'serviceproviderservicetype/getserviceproviderservicetypes' + serviceProviderId);
  }

  createBooking(createBooking: ICreateBooking) {
    const token = localStorage.getItem('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.baseUrl + 'booking/createbooking',createBooking,{headers});
  }
}
