import { Template } from '@angular/compiler/src/render3/r3_ast';
import { Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookingService } from '../booking/booking.service';
import { ICreateBooking, IServiceProviderServiceTypeRequestDetails } from '../shared/models/createBooking';
import { ICreatedBookingResponse } from '../shared/models/createdBookingResponse';
import { IGetBooking } from '../shared/models/getBooking';
import { IServiceProviderDetails } from '../shared/models/serviceProviderDetails';
import { IServiceProvderServiceType } from '../shared/models/serviceProviderServiceType';
import { UserService } from '../shared/services/UserService';
import { ServiceProviderDetailsService } from './service-provider-details.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-service-provider-details',
  templateUrl: './service-provider-details.component.html',
  styleUrls: ['./service-provider-details.component.scss']
})
export class ServiceProviderDetailsComponent implements OnInit {
  selected = new FormControl('valid');
  toppings = new FormControl();
  userInfoForm: FormGroup;
  public serviceProviderDetails: IServiceProviderDetails;
  public matcher = new MyErrorStateMatcher();
  public countries: Array<string> = ['dfsfa', 'asfasf'];
  public serviceProviderServiceTypesPrimary: Array<IServiceProvderServiceType> = [];
  public serviceProviderServiceTypesSecondary: Array<IServiceProvderServiceType> = [];
  public selectedServiceProviderId = 0;
  public createdBooking: ICreatedBookingResponse;
  public isConfirmationInProgress = false;
  public role = "";
  public registration = "";
  public isNextClickEnabled = true;
  closeResult = '';
  public innerWidth: number;
  public customerConfirmation = false;
  public errors: string;
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  @ViewChild('timeExpired') timeExpired: TemplateRef<any>;

  constructor(private activatedRoute: ActivatedRoute,
    private serviceProviderDetailsService: ServiceProviderDetailsService,
    private userService: UserService,
    private router: Router,
    private bookingService: BookingService,
    private fb: FormBuilder,
    private modalService: NgbModal) {
    this.userService.OnUserLoggedIn.subscribe(() => {
      this.role = this.userService.getRole();
    });
    this.userService.OnUserLoggedOut.subscribe(() => {
      this.role = "";
    });
  }

  ngOnInit(): void {
    this.loadYocoScript();
    this.createUserInfoForm();
    this.selectedServiceProviderId = parseInt(this.activatedRoute.snapshot.queryParamMap.get('id'));
    this.getServiceProvider(this.selectedServiceProviderId);
    this.getServiceProviderServiceTypes(this.selectedServiceProviderId);
    this.role = this.userService.getRole();
    this.innerWidth = window.innerWidth;
  }

  getServiceProvider(id: number) {
    this.serviceProviderDetailsService.getServiceProvider(id).subscribe(res => {
      this.serviceProviderDetails = (res as IServiceProviderDetails);
    })
  }
  public onselectedChange() {
    console.log(this.userInfoForm.value);
    if (this.role === 'ServiceProvider') {
      if ((this.userInfoForm.value.registrationNumber && this.userInfoForm.value.registrationNumber !== '') && ((this.selected.value && this.selected.value !== '') || (this.toppings.value !== null && this.toppings.value.length > 0))) {
        this.isNextClickEnabled = false;
      }
      else {
        this.isNextClickEnabled = true;
      }
    } else if (this.role === 'Customer') {
      if ((this.selected.value && this.selected.value !== '') || (this.toppings.value !== null && this.toppings.value.length > 0)) {
        this.isNextClickEnabled = false;
      }
      else {
        this.isNextClickEnabled = true;
      }
    }

  }

  createUserInfoForm() {
    this.userInfoForm = this.fb.group({
      registrationNumber: [null, [Validators.required]]
    });
  }
  public getServiceProviderServiceTypes(serviceProviderId: number) {
    this.serviceProviderDetailsService.getServiceProviderServiceTypes(serviceProviderId).subscribe(res => {
      this.serviceProviderServiceTypesPrimary = (res as Array<IServiceProvderServiceType>);
      this.serviceProviderServiceTypesPrimary = this.serviceProviderServiceTypesPrimary.filter(f => { return f.type === 1 });
      this.serviceProviderServiceTypesSecondary = (res as Array<IServiceProvderServiceType>);
      this.serviceProviderServiceTypesSecondary = this.serviceProviderServiceTypesSecondary.filter(f => { return f.type === 2 });
      console.log(res);
    });
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onCustomerConfirmation() {
    console.log(this.createdBooking);
    const isTimeOver = this.checkBookingTimeElapse();
    if(isTimeOver){
      this.open(this.timeExpired);
    }
    else{
      this.customerConfirmation = true;
    }
  }

  public onClickNext() {
    console.log(this.selected.value);
    const primaryService = this.serviceProviderServiceTypesPrimary.filter(spt => spt.name === this.selected.value)[0];
    console.log(this.toppings.value);
    let secondaryServices: Array<IServiceProvderServiceType> = [];
    if (this.toppings.value !== null) {
      this.toppings.value.forEach(service => {
        const secondaryService = this.serviceProviderServiceTypesSecondary.filter(spt => spt.name === service)[0];
        secondaryServices.push(secondaryService);
      });
    }

    let requestDetails = Array<IServiceProviderServiceTypeRequestDetails>();

    if (primaryService) {
      const requestDetailsPrimary: IServiceProviderServiceTypeRequestDetails = {
        serviceProviderServiceTypeId: primaryService.id,
        duration: primaryService.duration,
        rate: primaryService.rate,
      };
      requestDetails.push(requestDetailsPrimary);
    }

    secondaryServices.forEach(service => {
      const requestDetailsSecondary: IServiceProviderServiceTypeRequestDetails = {
        serviceProviderServiceTypeId: service.id,
        duration: service.duration,
        rate: service.rate,
      };
      requestDetails.push(requestDetailsSecondary);
    });

    let appUserId = this.userService.getUserId();

    const createBooking: ICreateBooking = {
      serviceProviderId: this.selectedServiceProviderId,
      appUserId: appUserId,
      requestDetails: requestDetails,
      bookingDate: new Date(),
      registrationNumber: this.userInfoForm.value.registrationNumber,
    }
    console.log(createBooking);
    this.serviceProviderDetailsService.createBooking(createBooking).subscribe(res => {
      this.createdBooking = (res as ICreatedBookingResponse);
      this.isConfirmationInProgress = true;
    }, error => {
      console.log(error);
      this.errors = error.error.errors.Error;
      console.log(this.errors);
    });
    console.log(this.selected.value);
    console.log(this.toppings.value);
  }

  public onBackToDashboardClicked() {
    this.router.navigateByUrl('/');
  }

  public onConfirmClicked() {
    localStorage.setItem('bookingId', this.createdBooking.bookingId.toString());
    this.router.navigateByUrl('/bookingsucceeded');
  }

  public onCanceledClicked() {
    let getBooking: IGetBooking = new IGetBooking();
    getBooking.bookingId = this.createdBooking.bookingId;
    console.log(getBooking);
    this.bookingService.cancelBooking(getBooking).subscribe(res => {
    });
    this.router.navigateByUrl('/');
  }

  private checkBookingTimeElapse(){
    const convertedBooking = new Date(this.createdBooking.createdDate.toString())
    const bookingTimeToCheck = new Date(convertedBooking.getTime() + 5*60000);
    const timeNow = new Date();
    console.log(bookingTimeToCheck);
    if( timeNow > bookingTimeToCheck){
      return true;
    }
    else
      return false
  }
  

  public loadYocoScript() {

    if (!window.document.getElementById('yoco-script')) {
      var s = window.document.createElement("script");
      s.id = "yoco-script";
      s.type = "text/javascript";
      s.src = "https://js.yoco.com/sdk/v1/yoco-sdk-web.js";
      window.document.body.appendChild(s);
    }
  }

  public Pay() {
    let total = this.createdBooking.totalCost + (this.createdBooking.bookingFee * this.createdBooking.totalCost);
    let amount = +(total * 100).toFixed();
    var yoco = new (<any>window).YocoSDK({
      publicKey: 'pk_test_ed3c54a6gOol69qa7f45',
    });
    var localMyDiv = this.myDiv;

    // let paymentservicelocal = this.paymentService;
    // var localLoaderService = this.loaderService;
    // let localYocoSuccess = this.yocoSuccessObservable;
    // let localsucessPayement = this.successfullPayment;
    // let localBasketService = this.basketService;
    // let localOrderService = this.orderService;
    // let success = false;
    // let basketId = localStorage.getItem('basket_id');
    // let orderAddress = this.checkoutForm.value as IAddress;
    // orderAddress.country = 'ZA';
    localStorage.setItem('bookingId', this.createdBooking.bookingId.toString());
    yoco.showPopup({
      amountInCents: amount,
      currency: 'ZAR',
      name: 'Your Store or Product',
      description: 'Awesome description',
      callback: function (result) {
        // This function returns a token that your server can use to capture a payment
        if (result.error) {
          const errorMessage = result.error.message;
          // localLoaderService.toggleLoader(false);
        }
        else {
          if (localMyDiv !== undefined) {
            let el: HTMLElement = localMyDiv.nativeElement;
            el.click();
          }
          // let paymentCharge: IPaymentChargeRequest = {
          //   token: result.id,
          //   amountInCents: amount,
          //   currency: 'ZAR',
          //   basketId: localStorage.getItem('basket_id'),
          //   address: orderAddress
          // }

          // paymentservicelocal.createPaymentCharge(paymentCharge).subscribe(res => {
            
          //   var paymentChargeResponse = res as any;
          //   if (paymentChargeResponse.status === 'successful') {
          //     localBasketService.deleteBasketByID(localStorage.getItem('basket_id'));
          //   }
          //   else{
          //     localLoaderService.toggleLoader(false);
          //   }
          // }
          // )
        }
        // In a real integration - you would now pass this chargeToken back to your
        // server along with the order/basket that the customer has purchased.
      },
      onCancel: function () {
        // if (basketId !== null) {
        //   localLoaderService.toggleLoader(false);
        //   localOrderService.checkAndAbortOrder(basketId).subscribe(result => { });
        // }
      }
    });
  }

  public proceedToPaymentClick() { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    console.log(this.innerWidth);
  }

  reloadCurrentPage() {
    window.location.reload();
   }


}
