<!-- Begin Page Content -->
<div class="container-fluid" style="overflow: auto; max-height: 85vh;">
  <div class="row">

    <!-- Area Chart -->
    <div class="col-xl-12 col-12">
      <ng-container *ngIf="this.serviceProviderDetails">
        <div class="card shadow mb-4">
          <!-- Card Header - Dropdown -->
          <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h2 class="m-0 font-weight-bold text-primary">{{this.serviceProviderDetails.serviceProviderName}}</h2>
          </div>
          <!-- Card Body -->
          <div class="card-body">
            <ul class="list-unstyled">
              <li>
                <div class="row" >
                  <div class="col-xl-2 col-2" style="text-align: center;"><i class="fa fa-map-marker fa-2x"></i></div>
                  <div class="col-xl-10 col-10">
                    <span> {{this.serviceProviderDetails.fullAddress}}</span><br>
                    <span> {{this.serviceProviderDetails.region}}</span><br>
                    <span> {{this.serviceProviderDetails.city}}</span><br>
                    <span> {{this.serviceProviderDetails.postalCode}}</span>
                  </div>
                </div>
              </li>

              <li>
                <div class="row" style="margin-top: 10px;">
                  <div class="col-xl-2 col-2" style="text-align: center;"><i class="fa fa-phone mt-4 fa-2x"
                      style="margin-top: 0px !important;"></i></div>
                  <div class="col-xl-10 col-10">
                    <span> {{this.serviceProviderDetails.contactNumberOne}}</span>
                    <span> {{this.serviceProviderDetails.contactNumberTwo}}</span>
                  </div>
                </div>
              </li>

              <li>
                <div class="row" style="margin-top: 10px;">
                  <div class="col-xl-2 col-2" style="text-align: center;">
                    <i class="fa fa-envelope mt-4 fa-2x" style="margin-top: 0px !important;"></i>
                  </div>
                  <div class="col-xl-10 col-10">
                    <span> {{this.serviceProviderDetails.email}}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="row" *ngIf="!this.isConfirmationInProgress">

    <!-- Area Chart -->
    <div class="col-xl-12 col-12">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h4 class="m-0 font-weight-bold text-primary">Booking</h4>
        </div>
        <!-- Card Body -->
        <div class="card-body">
          <h6 *ngIf="innerWidth<1200" class="m-0 font-weight-bold text-primary mb-4" style="max-width: 300px;">
            Please select at least one of the options below to start
            your booking for today</h6>
          <h6 *ngIf="innerWidth>1200" class="m-0 font-weight-bold text-primary mb-4">Please
            select at least one of the
            options below to start
            your booking for today</h6>
          <div class="form-group required">
            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Please select a Primary Service</mat-label>
              <mat-select (closed)="onselectedChange()" [formControl]="selected" [errorStateMatcher]="matcher">
                <mat-option>Clear</mat-option>
                <mat-option [value]="item.name" *ngFor="let item of this.serviceProviderServiceTypesPrimary">
                  {{item.name}} (Price: R{{item.rate}}, Duration: {{item.duration}})
                </mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="selected.hasError('required')">You must make a selection</mat-error> -->
              <!-- <mat-error *ngIf="selected.hasError('pattern') && !selected.hasError('required')">
                              Your selection is invalid
                            </mat-error> -->
            </mat-form-field>
          </div>
          <div class="form-group required">
            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Additional Services</mat-label>
              <mat-select (closed)="onselectedChange()" [formControl]="toppings" multiple>
                <mat-select-trigger>
                  {{toppings.value ? toppings.value[0] : ''}}
                  <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
                    (+{{toppings.value.length - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let topping of serviceProviderServiceTypesSecondary" [value]="topping.name">
                  {{topping.name}} (Price: R{{topping.rate}}, Duration: {{topping.duration}})
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <form [formGroup]="userInfoForm">
            <div class="form-group required" *ngIf="role === 'ServiceProvider'">
              <div class="col-xl-12 col-12 mb-3">
                <app-text-input (ngModelChange)="onselectedChange()" formControlName="registrationNumber"
                  [label]="'Registration number'">
                </app-text-input>
              </div>
            </div>
          </form>
          <h6 *ngIf="innerWidth<1200" class="m-0 font-weight-bold text-danger mb-4" style="max-width: 300px;">
            {{this.errors}}</h6>
          <h6 *ngIf="innerWidth>1200" class="m-0 font-weight-bold text-danger mb-4">{{this.errors}}</h6>
          <div class="form-group row float-right">
            <div class="col-xl-12 col-12" *ngIf="role === 'ServiceProvider' || role === 'Customer'">
              <button *ngIf="role === 'ServiceProvider'" class="btn btn-info" (click)="open(content)"
                [disabled]="this.isNextClickEnabled"><i class="fa fa-arrow-circle-right"></i> Next</button>
              <button *ngIf="role === 'Customer'" class="btn btn-info" (click)="onClickNext()"
                [disabled]="this.isNextClickEnabled"><i class="fa fa-arrow-circle-right"></i> Next</button>
              <button class="btn btn-info" (click)="onBackToDashboardClicked()" style="margin-left: 10px;"><i
                  class="fa fa-columns"></i> Back to Dashboard</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="this.isConfirmationInProgress">

    <!-- Area Chart -->
    <div class="col-xl-12 col-12">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h4 class="m-0 font-weight-bold text-primary">Confirm Booking</h4>
        </div>

        <!-- Card Body -->
        <div class="card-body">
          <h6 *ngIf="innerWidth<1200" class="m-0 font-weight-bold text-primary mb-4" style="max-width: 300px;">Please
            review your booking summary for today :</h6>
          <h6 *ngIf="innerWidth>1200" class="m-0 font-weight-bold text-primary mb-4">Please review your booking summary
            for today :</h6>
          <div>
            <div>
            <div class="row">
              <div class="col-xl-8 col-7">
                <label>Booking Date:</label>
              </div>
              <div class="col-xl-4 col-5">
                {{this.createdBooking.bookingDate | date}}
              </div>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="col-xl-8 col-7">
                <label>Booking Start Time</label>
              </div>
              <div class="col-xl-4 col-5">
                {{this.createdBooking.bookingStartTime | date:'shortTime' }}
              </div>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="col-xl-8 col-7">
                <label>Booking End Time</label>
              </div>
              <div class="col-xl-4 col-5">
                {{this.createdBooking.bookingEndTime | date:'shortTime' }}
              </div>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="col-xl-8 col-7">
                <label>Services Cost</label>
              </div>
              <div class="col-xl-4 col-5">
                R {{this.createdBooking.totalCost}}
              </div>
            </div>
          </div>
          <div *ngIf="role === 'Customer'">
            <div class="row">
              <div class="col-xl-8 col-7">
                <label>Service Fee</label>
              </div>
              <div class="col-xl-4 col-5">
                R {{this.createdBooking.bookingFee * this.createdBooking.totalCost}}
              </div>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="col-xl-8 col-7">
                <label>Total Cost</label>
              </div>
              <div class="col-xl-4 col-5" *ngIf="role === 'Customer'">
                R {{(this.createdBooking.bookingFee * this.createdBooking.totalCost)}}
              </div>
              <div class="col-xl-4 col-5" *ngIf="role !== 'Customer'">
                R {{this.createdBooking.totalCost}}
              </div>
            </div>
          </div>
          <div>
          </div>
          <p *ngIf="innerWidth<1200" class="m-0 font-weight-bold text-primary mb-4" style="max-width: 300px;">Bookings need to be confirmed within 5 minutes</p>
          <p *ngIf="innerWidth>1200" class="m-0 font-weight-bold text-primary mb-4">Bookings need to be confirmed within 5 minutes</p>
          <div class="form-group row float-right">
            <ng-container *ngIf="role !== 'ServiceProvider'">
              <div class="col-xl-6 col-6" style="padding-right: 0px;"
                *ngIf="role !== 'ServiceProvider' && customerConfirmation">
                <button (click)="Pay()" class="btn btn-info"><i class="fa fa-plus"></i> Pay Now</button>
                <a #myDiv hidden [routerLink]="['/bookingsucceeded']">sdfs</a>
              </div>
              <div class="col-xl-12 col-12" *ngIf="role !== 'ServiceProvider' && !customerConfirmation">
                <button style="margin-right:5px" class="btn btn-info" (click)="open(content2)"
                  [disabled]="this.isNextClickEnabled"><i class="fa fa-arrow-circle-right"></i> Confirm
                  Booking</button>
                <button class="btn btn-info" (click)="onCanceledClicked()"><i class="fa fa-window-close"></i>
                  Cancel</button>
              </div>
              <div class="col-xl-6 col-6" *ngIf="role !== 'ServiceProvider' && customerConfirmation">
                <button class="btn btn-info" (click)="onCanceledClicked()"><i class="fa fa-window-close"></i>
                  Cancel</button>
              </div>
            </ng-container>
            <div class="col-xl-12 col-12" *ngIf="role === 'ServiceProvider'">
              <button class="btn btn-info" (click)="onConfirmClicked()" [disabled]="this.isNextClickEnabled"><i
                  class="fa fa-arrow-circle-right"></i> Confirm
                Booking</button>
              <button class="btn btn-info" (click)="onCanceledClicked()" style="margin-left: 10px;"><i
                  class="fa fa-columns"></i> Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Booking Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        Are you sure that you want to confirm as no cancellations are allowed for this type of booking.
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')"
      (mouseup)="onClickNext()">Yes</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">No</button>
  </div>
</ng-template>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Booking Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        Are you sure that you want to confirm booking.
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')"
      (mouseup)="onCustomerConfirmation()">Yes</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">No</button>
  </div>

</ng-template>

<ng-template #timeExpired let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Booking Alert</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        Booking wait time exceeded please book again.
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')"
      (mouseup)="reloadCurrentPage()">Book again</button>
    <button type="button" class="btn btn-primary" routerLink="/" (click)="modal.close('Save click')">Cancel</button>
  </div>

</ng-template>
<!-- /.container-fluid -->