import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './account/login/login.component';
import { ServiceProviderManagementComponent } from './service-provider-management/service-provider-management.component';
import { ServiceProviderDetailsComponent } from './service-provider-details/service-provider-details.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { BookingSucceededComponent } from './booking/booking-succeeded/booking-succeeded.component';
import { BookingFailedComponent } from './booking/booking-failed/booking-failed.component';
import { AccountConfirmationComponent } from './confirmation/account-confirmation/account-confirmation.component';


const routes: Routes = [
  { path: '', component: HomeComponent, data: { breadcrumb: 'Home' } },
  { path: 'serviceprovidermanagement', component: ServiceProviderManagementComponent},
  { path: 'serviceproviderdetails', component: ServiceProviderDetailsComponent },
  { path: 'serviceproviderdetails/:id', component: ServiceProviderDetailsComponent },
  { path: 'myaccount', component: MyAccountComponent, data: { breadcrumb: 'MyAccount' } },
  { path: 'bookingsucceeded', component: BookingSucceededComponent, data: { breadcrumb: 'BookingSucceeded' } },
  { path: 'bookingsucceeded', component: BookingSucceededComponent, data: { breadcrumb: 'BookingSucceeded' } },
  { path: 'bookingfailed', component: BookingFailedComponent, data: { breadcrumb: 'BookingFailed' } },
  { path: 'accountconfirmation/:userid/:code', component: AccountConfirmationComponent, data: { breadcrumb: 'AccountConfirmation' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
