<div class="container-fluid" style="max-height: 85vh;">
    <app-my-account-user-info *ngIf="!this.isEditPassword && !this.isCreateAdmin" [isEdit]="this.isEditUserDetails"
        (onCancel)="onCancelEditUserDetails()"></app-my-account-user-info>
    <app-my-account-update-password *ngIf="this.isEditPassword" [isEdit]="this.isEditPassword"
        (onCancel)="onCancelUpdatePassword()"></app-my-account-update-password>
    <app-my-account-create-admin *ngIf="this.isCreateAdmin" (onCancel)="onCreateAdminEventEmitted()" (onSuccessfullRegister)="onCreateAdminEventEmitted()"></app-my-account-create-admin>
    <div class="row" *ngIf="!this.isEditPassword && !this.isEditUserDetails && this.role !== 'Admin'">
        <div class="col-xl-6 col-6">
            <a class="btn btn-primary btn-user btn-block" style="color:white" (click)="onEditUserDetailsClick()">
                Edit
            </a>
        </div>
        <div class="col-xl-6 col-6" >
            <a href="javascript:void(0)" (click)="onUpdatePasswordClick()" class="btn btn-google btn-user btn-block">
                Update Password
            </a>
        </div>
    </div>
    <div class="row" *ngIf="!this.isEditPassword && !this.isEditUserDetails && !this.isCreateAdmin && this.role === 'Admin'" >
        <div class="col-xl-2 col-2" style="padding-right: 0px;">
            <a class="btn btn-primary btn-user btn-block" style="color:white;" (click)="onEditUserDetailsClick()">
                Edit
            </a>
        </div>
        <div class="col-xl-4 col-4" style="padding-right: 0px;">
            <a class="btn btn-primary btn-user btn-block" style="color:white" (click)="onCreateAdminClicked()">
                Create Admin
            </a>
        </div>
        <div class="col-xl-6 col-6">
            <a href="javascript:void(0)" (click)="onUpdatePasswordClick()" class="btn btn-google btn-user btn-block">
                Update Password
            </a>
        </div>
    </div>
</div>