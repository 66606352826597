import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AccountService } from 'src/app/account/account.service';
import { ModalController } from 'src/app/controllers/modal.controller';
import { EventHub } from 'src/app/shared/events/EventHub';
import { UserService } from 'src/app/shared/services/UserService';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  public isUserLoggedIn: boolean = false;
  @Output() toggleSideBarForMobile = new EventEmitter();
  public firstName = "";
  public token = "";
  closeResult = '';
  public isLoginFormActive = true;
  public isForgotPasswordActive = false;

  constructor(public modalController: ModalController,
    public ngxSmartModalService: NgxSmartModalService, private userService: UserService, private accountService: AccountService,private modalService: NgbModal,private eventHub:EventHub) {
    this.userService.OnUserLoggedIn.subscribe(() => {
      this.firstName = this.userService.getFirstName();
      this.token = this.userService.getToken();
      if (this.token) {
        this.isUserLoggedIn = true;
      } else {
        this.isUserLoggedIn = false;
      }
    });
    this.userService.OnUserLoggedOut.subscribe(() =>{
      this.isUserLoggedIn = false;
    });
  }

  ngOnInit(): void {
    this.firstName = this.userService.getFirstName();
    this.token = this.userService.getToken();
    if (this.token) {
      this.isUserLoggedIn = true;
    } else {
      this.isUserLoggedIn = false;
    }
  }

  public toggleSidbar() {
    this.eventHub.toggleSideBarForMobile.emit();
  }

  public showLoginModal() {
    console.log('in');
    // tslint:disable-next-line: prefer-const
    let loginModal = this.ngxSmartModalService.getModal('loginModal');
    loginModal.dismissable = true;
    loginModal.backdrop = true;
    loginModal.hideDelay = 0;
    loginModal.open(true);
  }

  public logout() {
    this.accountService.logout();
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    this.isLoginFormActive = true;
    this.isForgotPasswordActive = false;
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public hideLoginForm(){
    this.isLoginFormActive = false;
  }

  public showLoginForm(){
    this.isLoginFormActive = true;
    this.isForgotPasswordActive = false;
  }

  public showForgotPassword(){
    this.isForgotPasswordActive = true;
    this.isLoginFormActive = false;
  }

}
