<div class="row">
    <div class="col-md-12" *ngIf="innerWidth>1200">
        <form [formGroup]="SPForm" (ngSubmit)="onSubmit()" class="user">
            <div class="form-group row">
                <div class="col-sm-6 mb-3 mb-sm-0">
                    <app-text-input formControlName="firstNames" [label]="'First Name'"></app-text-input>
                </div>
                <div class="col-sm-6">
                    <app-text-input formControlName="lastName" [label]="'Last Name'"></app-text-input>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6 mb-3 mb-sm-0">
                    <app-text-input formControlName="userName" [label]="'User Name'"></app-text-input>
                </div>
                <div class="col-sm-6">
                    <app-text-input formControlName="email" [label]="'Email Address'"></app-text-input>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6 mb-3 mb-sm-0">
                    <app-text-input formControlName="contactNumberOne" [label]="'Contact Number One'"></app-text-input>
                </div>
                <div class="col-sm-6">
                    <app-text-input formControlName="registration" [label]="'Registration'"></app-text-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <a class="btn btn-primary btn-user btn-block" style="color:white" (click)="onSubmit()">
                        Update Service Provider User Information
                    </a>
                </div>
                <div class="col-md-6">
                    <a href="javascript:void(0)" (click)="onCancel.emit()" class="btn btn-google btn-user btn-block">
                        Cancel
                    </a>
                </div>
            </div>

        </form>
    </div>
    <div class="col-12" *ngIf="innerWidth<1200" style="max-width: fit-content;">
        <form [formGroup]="SPForm" (ngSubmit)="onSubmit()" class="user">
            <div class="form-group row">
                <div class="col-6 mb-3 mb-sm-0">
                    <app-mobile-text-input formControlName="firstNames" [label]="'First Name'"></app-mobile-text-input>
                </div>
                <div class="col-6">
                    <app-mobile-text-input formControlName="lastName" [label]="'Last Name'"></app-mobile-text-input>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-6 mb-3 mb-sm-0">
                    <app-mobile-text-input formControlName="userName" [label]="'User Name'"></app-mobile-text-input>
                </div>
                <div class="col-6">
                    <app-mobile-text-input formControlName="email" [label]="'Email Address'"></app-mobile-text-input>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-6 mb-3 mb-sm-0">
                    <app-mobile-text-input formControlName="contactNumberOne" [label]="'Contact Number One'"></app-mobile-text-input>
                </div>
                <div class="col-6">
                    <app-mobile-text-input formControlName="registration" [label]="'Registration'"></app-mobile-text-input>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                    <a class="btn btn-primary btn-user btn-block" style="color:white" (click)="onSubmit()">
                        Update Service Provider User Information
                    </a>
                </div>
                <div class="col-4">
                    <a href="javascript:void(0)" (click)="onCancel.emit()" class="btn btn-google btn-user btn-block">
                        Cancel
                    </a>
                </div>
            </div>

        </form>
    </div>
</div>