<ng-container *ngIf="this.nextBooking.bookingId === undefined "> 
    Loading ...
    </ng-container>
<ng-container *ngIf="this.nextBooking.bookingId !== 0">

    <div>
      <app-count-down-timer></app-count-down-timer>
    </div>
    <hr />
    <h4 class="mb-3">{{this.nextBooking.serviceProviderDetails.serviceProviderName}}</h4>

    <ul class="list-unstyled">
      <li>
        <div class="row" *ngIf="this.nextBooking.bookingId !== undefined ">
          <div class="col-md-2 col-2" style="text-align: center;"><i class="fa fa-map-marker fa-2x"></i></div>
          <div class="col-md-10 col-10">
            <span> {{this.nextBooking.serviceProviderDetails.fullAddress}}</span><br>
            <span> {{this.nextBooking.serviceProviderDetails.region}}</span><br>
            <span> {{this.nextBooking.serviceProviderDetails.city}}</span><br>
            <span> {{this.nextBooking.serviceProviderDetails.postalCode}}</span>
          </div>
        </div>
      </li>

      <li>
        <div class="row" style="margin-top: 10px;" *ngIf="this.nextBooking.bookingId !== undefined ">
          <div class="col-md-2 col-2" style="text-align: center;"><i class="fa fa-phone mt-4 fa-2x"
              style="margin-top: 0px !important;"></i></div>
          <div class="col-md-10 col-10">
            <span> {{this.nextBooking.serviceProviderDetails.contactNumberOne}}</span>
            <span> {{this.nextBooking.serviceProviderDetails.contactNumberTwo}}</span>
          </div>
        </div>
      </li>

      <li>
        <div class="row" style="margin-top: 10px;" *ngIf="this.nextBooking.bookingId !== undefined ">
          <div class="col-md-2 col-2" style="text-align: center;">
            <i class="fa fa-envelope mt-4 fa-2x" style="margin-top: 0px !important;"></i>
          </div>
          <div class="col-md-10 col-10">
            <span> {{this.nextBooking.serviceProviderDetails.email}}</span>
          </div>
        </div>
      </li>

      <li>
        <div class="row" style="margin-top: 10px;" *ngIf="this.nextBooking.bookingId !== undefined ">
          <div class="col-md-2 col-2" style="text-align: center;">
            <i class="fa fa-cogs mt-4 fa-2x" style="margin-top: 0px !important;"></i>
          </div>
          <div class="col-md-10 col-10">
            <span> {{this.nextBooking.bookedServices}}</span>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>
<ng-container *ngIf="this.nextBooking.bookingId === 0">
  <div class="mb-4 text-center" style="padding-top:30%;">
    <span>You do not have an upcoming booking</span>
  </div>
</ng-container>
