<div class="row">
    <div class="col-xl-12 col-12">
        <form [formGroup]="AdminForm" (ngSubmit)="onSubmit()" class="user">
            <div class="form-group row">
                <div class="col-xl-6 col-6 mb-3 mb-sm-0">
                    <app-text-input formControlName="userName" [label]="'User Name'"></app-text-input>
                </div>
                <div class="col-xl-6 col-6 ">
                    <app-text-input formControlName="email" [label]="'Email Address'"></app-text-input>
                </div>
            </div>
            <div class="form-group">
                <app-text-input formControlName="password" [label]="'Password'"></app-text-input>

            </div>
            <div class="form-group row">
                <div class="col-xl-6 col-6 mb-3 mb-sm-0">
                    <app-text-input formControlName="fullAddress" [label]="'Full Address'"></app-text-input>
                </div>
                <div class="col-xl-6 col-6">

                </div>
            </div>
            <div class="form-group row">
                <div class="col-xl-6 col-6 mb-3 mb-sm-0">
                    <app-text-input formControlName="city" [label]="'City'"></app-text-input>
                </div>
                <div class="col-xl-6 col-6">
                    <app-text-input formControlName="region" [label]="'Region'"></app-text-input>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-xl-6 col-6 mb-3 mb-sm-0">
                    <app-text-input formControlName="postalCode" [label]="'Postal Code'" [type]="'number'">
                    </app-text-input>
                </div>
                <div class="col-xl-6 col-6">
                    <app-text-input formControlName="country" [label]="'Country'"></app-text-input>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-xl-6 col-6 mb-3 mb-sm-0">
                    <app-text-input formControlName="firstNames" [label]="'First Name'"></app-text-input>
                </div>
                <div class="col-xl-6 col-6">
                    <app-text-input formControlName="lastName" [label]="'Last Name'"></app-text-input>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-xl-6 col-6 mb-3 mb-sm-0">
                    <app-text-input formControlName="contactNumberOne" [label]="'Contact Number One'"></app-text-input>
                </div>
                <div class="col-xl-6 col-6">
                    <app-text-input formControlName="contactNumberTwo" [label]="'Contact Number Two'"></app-text-input>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-6">
                    <a class="btn btn-primary btn-user btn-block" style="color:white" (click)="onSubmit()">
                        Create Admin
                    </a>
                </div>
                <div class="col-xl-6 col-6">
                    <a href="javascript:void(0)" (click)="onCancel.emit()" class="btn btn-google btn-user btn-block">
                        Cancel
                    </a>
                </div>
            </div>

        </form>
    </div>
</div>