import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingSucceededComponent } from './booking-succeeded/booking-succeeded.component';
import { BookingFailedComponent } from './booking-failed/booking-failed.component';



@NgModule({
  declarations: [BookingSucceededComponent, BookingFailedComponent],
  imports: [
    CommonModule
  ],
  exports:[
    BookingSucceededComponent,
    BookingFailedComponent
  ]
})
export class BookingModule { }
