import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IRegisterServiceProvider } from 'src/app/shared/models/registerServiceProviderModel';
import { ServiceProviderService } from '../service-provider.service';

@Component({
  selector: 'app-service-provider-manage-user',
  templateUrl: './service-provider-manage-user.component.html',
  styleUrls: ['./service-provider-manage-user.component.scss']
})
export class ServiceProviderManageUserComponent implements OnInit {
  SPForm: FormGroup;
  public serviceProviderInfo: IRegisterServiceProvider;

  @Input() serviceProviderId: number = 0;

  @Input() innerWidth: number;

  @Output() onCancel = new EventEmitter();
  @Output() onSuccessfullRegister = new EventEmitter();

  constructor(private fb: FormBuilder, private serviceProviderService: ServiceProviderService) { }

  ngOnInit(): void {
    this.createSPForm();
    if (this.serviceProviderId === 0) {
      this.createSPForm();
    } else {
      this.getServiceProviderUserInfo(this.serviceProviderId);
    }

  }

  getServiceProviderUserInfo(id: number) {
    this.serviceProviderService.getServiceProvider(id).subscribe(response => {
      this.serviceProviderInfo = (response as IRegisterServiceProvider);
      this.fillSPForm();
    }, error => {
      console.log(error);
    });
  }

  createSPForm() {
    this.SPForm = this.fb.group({
      userName: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')]],     
      firstNames: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      contactNumberOne: [null, [Validators.required]],
      contactNumberTwo: [null, [Validators.required]],
      serviceProviderId: [this.serviceProviderId.toString(), [Validators.required]],
    });
  }

  fillSPForm() {
    this.SPForm = this.fb.group({
      userName: [this.serviceProviderInfo.username, [Validators.required]],
      email: [this.serviceProviderInfo.email, [Validators.required, Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')]],
      firstNames: [this.serviceProviderInfo.firstNames, [Validators.required]],
      lastName: [this.serviceProviderInfo.lastName, [Validators.required]],
      contactNumberOne: [this.serviceProviderInfo.contactNumberOne, [Validators.required]],
      contactNumberTwo: [this.serviceProviderInfo.contactNumberTwo, [Validators.required]],
      serviceProviderId: [this.serviceProviderId.toString(), [Validators.required]],
    });
  }

  public onSubmit() {
    const oldrgform = (this.SPForm.value as IRegisterServiceProvider);

    const rgform = (this.SPForm.value as IRegisterServiceProvider);
    rgform.postalCode = (oldrgform.postalCode as number);
    console.log(rgform);
    this.serviceProviderService.updateUserInformation(rgform).subscribe(response => {
      this.onSuccessfullRegister.emit();
    }, error => {
      console.log(error);
    });

  }
}