import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IGetBooking } from '../shared/models/getBooking';
import { IUser } from '../shared/models/user';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  baseUrl = environment.apiUrl;
  private currentUserSource = new BehaviorSubject<IUser>(null);
  currentUser$ = this.currentUserSource.asObservable();

  public OnNextBookingLoaded = new EventEmitter();
  public OnNextBookingCompleted = new EventEmitter();

  constructor(private http: HttpClient, private router: Router) { }

  confirmBooking(getBooking: IGetBooking) {
    const token = localStorage.getItem('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);

    return this.http.post(this.baseUrl + 'booking/confirmbooking', getBooking, { headers });
  }

  cancelBooking(getBooking: IGetBooking) {
    const token = localStorage.getItem('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);

    return this.http.post(this.baseUrl + 'booking/cancelBooking', getBooking, { headers });
  }

  getNextBooking(userId: string) {
    const token = localStorage.getItem('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);

    return this.http.get(this.baseUrl + 'booking/getNextBooking' + userId, { headers });
  }
}
