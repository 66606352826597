<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Forgot Password</h4>
    <div style="padding: 2px;">
        <button type="button" class="close" aria-label="Close" (click)="closeLoginModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="card-body p-0">
        <!-- Nested Row within Card Body -->
        <div class="row">
            <div class="col-lg-12">
                <form class="user" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <app-text-input formControlName="email" [label]="'Email'"></app-text-input>
                    </div>
                    <div class="form-group">
                        <app-text-input formControlName="password" type="password" [label]="'Password'"></app-text-input>
                    </div>
                    <div class="form-group">
                        <app-text-input (ngModelChange)="checkPasswords()" formControlName="confirmPassword"  type="password" [label]="'Confirm Password'"></app-text-input>
                    </div>
                    <ul class="text-danger list-unstyled" *ngIf="errors">
                        <li>
                            {{this.errors}}
                        </li>
                    </ul>
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <button class="btn btn-primary" type="submit">
                                <i class="fa fa-unlock"></i>
                                Reset Password
                            </button>
                        </div>
                    </div>
                </form>
                <hr>
                <div class="text-center">
                    <a class="small" href="javascript:void(0)" (click)="showLoginForm()">Already have an account? Login!</a>
                </div>
            </div>
        </div>
    </div>
</div>