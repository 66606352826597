import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IUserInfo, IUserInfoUpdate } from 'src/app/shared/models/userInfo';
import { UserService } from 'src/app/shared/services/UserService';
import { MyAccountService } from '../my-account.service';

@Component({
  selector: 'app-my-account-user-info',
  templateUrl: './my-account-user-info.component.html',
  styleUrls: ['./my-account-user-info.component.scss']
})
export class MyAccountUserInfoComponent implements OnInit {

  userInfoForm: FormGroup;
  public userId = "";
  public role = "";
  public userInfo: IUserInfo;

  public innerWidth: number;

  @Input() isEdit: boolean = false;

  @Output() onCancel = new EventEmitter();
  @Output() onSuccessUpdateUserInfo = new EventEmitter();

  constructor(private fb: FormBuilder, private myAccountService: MyAccountService, private userService: UserService) { }

  ngOnInit(): void {
    this.createUserInfoForm();
    this.userId = this.userService.getUserId();
    this.role = this.userService.getRole();
    this.getUserInfo(this.userId);
    this.innerWidth = window.innerWidth;
  }

  getUserInfo(userId: string) {
    console.log(userId);
    this.myAccountService.getUserInfo(userId).subscribe(response => {
      console.log(response);
      this.userInfo = (response as IUserInfo);
      this.fillUserInfoForm();
    }, error => {
      console.log(error);
    });
  }

  createUserInfoForm() {
    this.userInfoForm = this.fb.group({
      firstNames: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null, [Validators.required]],
      contactNumberOne: [null, [Validators.required]],
      contactNumberTwo: [null, [Validators.required]],
      registrationNumber: [null, [Validators.required]]
    });
  }

  fillUserInfoForm() {
    this.userInfoForm = this.fb.group({
      firstNames: [this.userInfo.firstNames, [Validators.required]],
      lastName: [this.userInfo.lastName, [Validators.required]],
      email: [this.userInfo.email, [Validators.required]],
      contactNumberOne: [this.userInfo.contactNumberOne, [Validators.required]],
      contactNumberTwo: [this.userInfo.contactNumberTwo, [Validators.required]],
      registrationNumber: [this.userInfo.registrationNumber, [Validators.required]]
    });
  }

  public onCancelClicked(){
    this.onCancel.emit();
    this.isEdit = false;
  }
  public onSubmit() {
    const oldrgform = (this.userInfoForm.value as IUserInfo);

    const rgform = (this.userInfoForm.value as IUserInfoUpdate);
    rgform.userId = this.userId;
    rgform.contactNumberTwo = "";
    console.log(rgform);
    this.myAccountService.update(rgform).subscribe(response => {
      this.onSuccessUpdateUserInfo.emit();
    }, error => {
      console.log(error);
    });

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    console.log(this.innerWidth);
  }
}
