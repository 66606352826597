import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IConfirmEmail } from 'src/app/shared/models/confirmEmail';
import { ConfirmationService } from '../confirmation.service';

@Component({
  selector: 'app-account-confirmation',
  templateUrl: './account-confirmation.component.html',
  styleUrls: ['./account-confirmation.component.scss']
})
export class AccountConfirmationComponent implements OnInit,AfterViewInit {
  public userId: any;
  public code: any;
  public confirmEmail = new IConfirmEmail;
  public confirmationSuccess = false;

  constructor(private activatedRoute: ActivatedRoute,private confirmEmailService: ConfirmationService) { }
  ngAfterViewInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      console.log(params.get('code'));
      this.confirmEmail.userId = params.get('userid');
      this.confirmEmail.code = params.get('code');
      
      this.confirmEmailService.confirmEmailAddress(this.confirmEmail).subscribe(res =>{
        console.log(res);
        if (res) {
          
          this.confirmationSuccess = true;
          console.log(this.confirmationSuccess);
          
        }
      });
    });
  }

  ngOnInit(): void {

  }

}
